import { ReactElement, useState } from 'react';
import { t } from 'i18next';
import { EmailReplyTabItemType } from 'src/types/adminTypes';
import {
  EmailReplyButtonContainer,
  EmailReplyTopBar,
  EmailReplyTopBarContainer,
  EmailAndThreadBodyContainer,
  ReplyTextButton,
  TabItem,
  TabItemText,
  TabsContainer,
  EmailThreadDetailsLoadingGrid,
  EmailThreadDetailsNotFoundViewerWrapper,
  EmailDetailsNotFoundTypography,
  EmailAndThreadMainContainer,
  EmailReplyFormContainer,
  EmailReplyContainer,
  EmailReplyLoader,
} from './EmailAndThreadTabsBarStyled';
import EmailReplyForm from '@components/email-reply-form/EmailReplyForm';
import { produce } from 'immer';
import EmailResponseTextViewer from '@components/email-response-text-viewer/EmailResponseTextViewer';
import { getEmailThreadById } from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { GetEmailThreadDataRow } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import Loader from '@components/loaders/Loader';
import { formatDate } from '@modules/home/utils/Utils';
import {
  EmailRecipientTypes,
  EmailReplyTypes,
} from '@constants/global-constants/constants';
import { getRecipientFromEmailRecipientsListByType } from '@utils/miscellaneousUtils';
import { APP } from 'src/styles/variables';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { resetEmailReplyForm } from '@modules/home/state/email/EmailSlice';

interface IEmailAndThreadTabsBarProps {
  renderEmailViewerComponent: () => ReactElement | null;
  isOriginalEmailLoading: boolean;
  emailId: string;
  showReplyFormButtons?: boolean;
}

interface IEmailAndThreadDetailsState {
  isEmailReplyFormOpen: boolean;
  emailThreadDetails: GetEmailThreadDataRow[];
  isEmailThreadDetailsLoading: boolean;
  currentActiveIndex: number;
  emailReplyType: string;
  isEmailReplyFromOpen: boolean;
  isEmailReplyAllFromOpen: boolean;
}

const initialEmailAndThreadDetailsState: IEmailAndThreadDetailsState = {
  isEmailReplyFormOpen: false,
  emailThreadDetails: [],
  isEmailThreadDetailsLoading: true,
  currentActiveIndex: 0,
  emailReplyType: '',
  isEmailReplyFromOpen: false,
  isEmailReplyAllFromOpen: false,
};

const EmailAndThreadTabsBar = (
  props: IEmailAndThreadTabsBarProps,
): ReactElement => {
  const {
    renderEmailViewerComponent,
    isOriginalEmailLoading,
    emailId = '',
    showReplyFormButtons = false,
  } = props;

  const [emailReplyAndThreadState, setEmailReplyAndThreadState] =
    useState<IEmailAndThreadDetailsState>(initialEmailAndThreadDetailsState);

  const {
    isEmailReplyFormOpen,
    emailThreadDetails,
    isEmailThreadDetailsLoading,
    currentActiveIndex,
    emailReplyType,
  } = emailReplyAndThreadState;

  const { emailReplyBody, emailReplyAttachments, isSendingReplyEmail } =
    useSelector((state: RootState) => state.email);

  const dispatch = useDispatch();

  const emailDetailsTabsList: EmailReplyTabItemType[] = [
    {
      id: 0,
      name: t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.EMAIL'),
      isActive: currentActiveIndex === 0,
    },
    {
      id: 1,
      name: t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.THREAD'),
      isActive: currentActiveIndex === 1,
    },
  ];

  const setCurrentActiveTab = (tabIndex: number) => {
    setEmailReplyAndThreadState(
      produce(draft => {
        draft.currentActiveIndex = tabIndex;
      }),
    );
  };

  // API section starts

  const fetchEmailThreadById = async () => {
    if (emailId) {
      setEmailReplyAndThreadState(
        produce(draft => {
          draft.isEmailThreadDetailsLoading = true;
        }),
      );
      try {
        const EmailThreadDetailsByIdResponse = await getEmailThreadById(
          emailId,
        );
        setEmailReplyAndThreadState(
          produce(draft => {
            draft.emailThreadDetails = EmailThreadDetailsByIdResponse.data.rows;
          }),
        );
      } catch (errorResponse) {
        const errMessage = getErrorMessage(errorResponse);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        setEmailReplyAndThreadState(
          produce(draft => {
            draft.isEmailThreadDetailsLoading = false;
          }),
        );
      }
    }
  };

  // API region ends

  const updateIsReplyFormOpen = (value: boolean) => {
    setEmailReplyAndThreadState(
      produce(draft => {
        draft.isEmailReplyFormOpen = value;
      }),
    );
  };

  const handleEmailTabButtonClick = async () => {
    if (emailReplyBody.length > 0 || emailReplyAttachments.length > 0) {
      updateIsReplyFormOpen(true);
    }
  };

  const handleThreadTabButtonClick = async () => {
    updateIsReplyFormOpen(false);
    await fetchEmailThreadById();
  };

  const handleTabButtonClick = async (
    emailReplyTabItem: EmailReplyTabItemType,
  ) => {
    setCurrentActiveTab(emailReplyTabItem.id);
    if (emailReplyTabItem.id === emailDetailsTabsList[0].id) {
      handleEmailTabButtonClick();
    } else if (emailReplyTabItem.id === emailDetailsTabsList[1].id) {
      await handleThreadTabButtonClick();
    }
  };

  const renderEmailDetailsTabItem = (
    emailReplyTabItem: EmailReplyTabItemType,
  ) => (
    <TabItem
      key={`${emailReplyTabItem.name}-${emailReplyTabItem.id}`}
      isActive={emailReplyTabItem.isActive}
      onClick={async () => {
        await handleTabButtonClick(emailReplyTabItem);
      }}
    >
      <TabItemText isActive={emailReplyTabItem.isActive}>
        {emailReplyTabItem.name}
      </TabItemText>
    </TabItem>
  );

  const renderEmailReplyTabs = () => (
    <TabsContainer>
      {emailDetailsTabsList.map((emailReplyTabItem: EmailReplyTabItemType) =>
        renderEmailDetailsTabItem(emailReplyTabItem),
      )}
    </TabsContainer>
  );

  const handleReplyButton = async (replyEmailType: string) => {
    if (
      replyEmailType !== emailReplyAndThreadState.emailReplyType &&
      !emailReplyBody.length &&
      !emailReplyAttachments.length
    ) {
      dispatch(resetEmailReplyForm());
    }
    if (replyEmailType === EmailReplyTypes.Reply) {
      setEmailReplyAndThreadState(
        produce(draft => {
          draft.emailReplyType = replyEmailType;
        }),
      );
    } else if (replyEmailType === EmailReplyTypes.ReplyAll) {
      setEmailReplyAndThreadState(
        produce(draft => {
          draft.emailReplyType = replyEmailType;
        }),
      );
    }

    setEmailReplyAndThreadState(
      produce(draft => {
        draft.isEmailReplyFormOpen = true;
      }),
    );

    setCurrentActiveTab(emailDetailsTabsList[0].id);
  };

  const handleSendEmailSuccess = async () => {
    setEmailReplyAndThreadState(
      produce(draft => {
        draft.isEmailReplyFormOpen = false;
      }),
    );
    setCurrentActiveTab(emailDetailsTabsList[1].id);
    await fetchEmailThreadById();
  };

  const handleDeleteEmailReplyForm = () => {
    setEmailReplyAndThreadState(
      produce(draft => {
        draft.isEmailReplyFormOpen = false;
        draft.emailReplyType = '';
      }),
    );
  };

  const renderEmailReplyButtons = () => {
    if (!showReplyFormButtons) {
      return null;
    } else {
      return (
        <EmailReplyButtonContainer>
          <ReplyTextButton
            onClick={() => handleReplyButton(EmailReplyTypes.Reply)}
            fontSize={APP.FONT.FONT_SIZE.BODY3}
          >
            {t('ADMIN.EMAIL.REPLY')}
          </ReplyTextButton>
          <ReplyTextButton
            onClick={() => handleReplyButton(EmailReplyTypes.ReplyAll)}
            fontSize={APP.FONT.FONT_SIZE.BODY3}
          >
            {t('ADMIN.EMAIL.REPLY_ALL')}
          </ReplyTextButton>
        </EmailReplyButtonContainer>
      );
    }
  };

  const renderEmailReplyTopBar = () => {
    return (
      <EmailReplyTopBar>
        {renderEmailReplyTabs()}
        {renderEmailReplyButtons()}
      </EmailReplyTopBar>
    );
  };

  const renderEmailReply = () => {
    return (
      <EmailReplyForm
        onDeleteEmailReply={handleDeleteEmailReplyForm}
        onSendSuccess={handleSendEmailSuccess}
        emailReplyType={emailReplyType}
        emailId={emailId}
      />
    );
  };

  const renderEmailReplyFormViewer = () => {
    if (isEmailReplyFormOpen) {
      return (
        <EmailReplyFormContainer>
          <EmailReplyContainer>{renderEmailReply()}</EmailReplyContainer>
        </EmailReplyFormContainer>
      );
    }
  };

  const renderEmailThreadDetails = () => {
    if (
      emailId.length > 0 &&
      (isEmailThreadDetailsLoading || isOriginalEmailLoading)
    ) {
      return (
        <EmailThreadDetailsLoadingGrid>
          <Loader />
        </EmailThreadDetailsLoadingGrid>
      );
    } else {
      if (emailThreadDetails?.length > 0) {
        const emailThreadDetailsLength = emailThreadDetails?.length - 1;
        return (
          <EmailAndThreadBodyContainer>
            {emailThreadDetails.map(
              (emailThreadItem: GetEmailThreadDataRow, index: number) => (
                <EmailResponseTextViewer
                  key={emailThreadItem?.id ?? index}
                  fromEmail={emailThreadItem?.fromEmail ?? ''}
                  toEmail={
                    emailThreadItem?.recipients &&
                    emailThreadItem.recipients?.length > 0
                      ? getRecipientFromEmailRecipientsListByType(
                          emailThreadItem.recipients,
                          EmailRecipientTypes.to,
                        )
                      : ''
                  }
                  cc={
                    emailThreadItem?.recipients &&
                    emailThreadItem.recipients?.length > 0
                      ? getRecipientFromEmailRecipientsListByType(
                          emailThreadItem.recipients,
                          EmailRecipientTypes.cc,
                        )
                      : ''
                  }
                  body={emailThreadItem?.body ?? ''}
                  receivedAt={
                    emailThreadItem?.receivedAt
                      ? formatDate(emailThreadItem?.receivedAt)
                      : ''
                  }
                  files={emailThreadItem?.attachments ?? []}
                  isLastEmailThreadIndex={emailThreadDetailsLength === index}
                />
              ),
            )}
          </EmailAndThreadBodyContainer>
        );
      } else {
        return (
          <EmailThreadDetailsNotFoundViewerWrapper>
            <EmailDetailsNotFoundTypography>
              {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_THREAD_DETAILS')}
            </EmailDetailsNotFoundTypography>
          </EmailThreadDetailsNotFoundViewerWrapper>
        );
      }
    }
  };

  return (
    <EmailAndThreadMainContainer>
      <EmailReplyTopBarContainer>
        {renderEmailReplyTopBar()}
      </EmailReplyTopBarContainer>

      {isSendingReplyEmail ? (
        <EmailReplyLoader>
          <Loader size={60} />
        </EmailReplyLoader>
      ) : null}

      {isEmailReplyFormOpen ? renderEmailReplyFormViewer() : null}

      <EmailAndThreadBodyContainer>
        {emailDetailsTabsList[0].isActive
          ? renderEmailViewerComponent()
          : emailDetailsTabsList[1].isActive
          ? renderEmailThreadDetails()
          : null}
      </EmailAndThreadBodyContainer>
    </EmailAndThreadMainContainer>
  );
};

export default EmailAndThreadTabsBar;
