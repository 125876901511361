import { RecipientDataDto } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const joinArrayElementsWithComma = (arr: string[]): string => {
  if (arr.length < 2) {
    return arr[0];
  }
  const result =
    arr.slice(0, -1).join(', ') +
    (arr.length > 1 ? ', ' : '') +
    arr[arr.length - 1];
  return result;
};

export const generateRandomTableName = (prefixValue: string): string => {
  const randomSuffix = Math.random().toString(36).substring(2, 10);
  return `${prefixValue}-${randomSuffix}`;
};

export const getRecipientFromEmailRecipientsListByType = (
  recipientsList: RecipientDataDto[],
  recipientType: string,
): string =>
  recipientsList
    .filter(({ type }) => type?.toLowerCase() === recipientType)
    .map(({ email }) => email)
    .join(', ');

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
