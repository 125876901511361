import { ReactElement, useState } from 'react';
import { produce } from 'immer';
import saveAs from 'file-saver';
import {
  DateAndFromEmailDiv,
  EmailResponseAttachmentContentGrid,
  EmailAttachmentIconImage,
  EmailAttachmentNameTypography,
  EmailResponseAttachmentWrapperGrid,
  EmailResponseDetailsContainerGrid,
  EmailDivStyled,
  KeywordStyledSpan,
  RichTextEmailResponseViewer,
  ValueStyledSpan,
  DateStyledSpan,
  // ResponseKeyword,
  // ResponseKeywordDivStyled,
} from './EmailResponseTextViewerStyle';
import AttachmentIcon from '@assets/images/Attachment.svg';
import EmailAttachmentViewerDialog from '@components/dialog/email-attachment-viewer-dialog/EmailAttachmentViewerDialog';
import { t } from 'i18next';
import { AttachmentDataDto } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { UNSUPPORTED_FILE_TYPES_ATTACHMENT_PREVIEW } from '@constants/global-constants/constants';
import moment from 'moment';

interface IEmailRichTextViewer {
  fromEmail: string;
  toEmail: string;
  cc?: string;
  subject?: string;
  body: string;
  files?: AttachmentDataDto[] | any[];
  receivedAt: string;
  // isEmailTabActive?: boolean;
  isLastEmailThreadIndex?: boolean;
}

const EmailResponseTextViewer = (props: IEmailRichTextViewer): ReactElement => {
  const {
    fromEmail,
    toEmail,
    cc,
    body,
    files,
    receivedAt,
    // isEmailTabActive,
    isLastEmailThreadIndex,
  } = props;
  const [state, setState] = useState({
    shouldDialogOpen: false,
    isDialogContentLoading: false,
    dialogTitle: '',
    dialogContent: '',
    base64DataURL: '',
    isMimeTypeUnsupported: false,
  });
  const modules = {
    toolbar: false,
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const emailReceivedFormattedDate = moment(receivedAt).format('M/D/YY');

  const extractEmailAttachment = (attachmentItem: AttachmentDataDto) => {
    setState(
      produce(draft => {
        draft.isDialogContentLoading = true;
      }),
    );
    try {
      const base64Data = attachmentItem?.data ?? '';

      const fetchedAttachmentData = {
        fileTitle: attachmentItem.name,
        fileData: `data:${attachmentItem.contentType};base64,${base64Data}`,
      };

      if (
        UNSUPPORTED_FILE_TYPES_ATTACHMENT_PREVIEW.includes(
          attachmentItem.contentType,
        )
      ) {
        setState(
          produce(draft => {
            draft.isMimeTypeUnsupported = true;
          }),
        );
      } else {
        setState(
          produce(draft => {
            draft.isMimeTypeUnsupported = false;
          }),
        );
      }

      setState(
        produce(
          (draft: {
            shouldDialogOpen: boolean;
            dialogTitle: any;
            dialogContent: string;
            base64DataURL: string;
            isDialogContentLoading: boolean;
          }) => {
            draft.shouldDialogOpen = true;
            draft.dialogTitle = attachmentItem.name;
            draft.dialogContent = fetchedAttachmentData.fileData;
            draft.base64DataURL = fetchedAttachmentData.fileData;
            draft.isDialogContentLoading = false;
          },
        ),
      );
    } catch (e) {
      const errorMessage = t('QUEUE_DETAILS.EMAIL_ATTACHMENT_VIEW_ERROR');
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setState(
        produce(draft => {
          draft.isDialogContentLoading = false;
        }),
      );
    }
  };

  const handleEmailAttachmentClick = (
    emailAttachmentItem: AttachmentDataDto,
  ) => {
    setState(prevState => ({
      ...prevState,
      shouldDialogOpen: true,
      isDialogContentLoading: true,
    }));

    setState(
      produce(
        (draft: {
          shouldDialogOpen: boolean;
          isDialogContentLoading: boolean;
        }) => {
          draft.shouldDialogOpen = true;
          draft.isDialogContentLoading = true;
        },
      ),
    );
    extractEmailAttachment(emailAttachmentItem);
  };

  const handleEmailAttachmentDownload = () => {
    saveAs(state.base64DataURL, state.dialogTitle);
  };

  const dialogClose = () => {
    setState(prevState => ({
      ...prevState,
      shouldDialogOpen: false,
    }));
  };

  const renderEmailAttachments = (
    emailAttachmentsList: AttachmentDataDto[],
  ) => {
    return emailAttachmentsList.map(emailAttachmentItem => {
      return (
        <EmailResponseAttachmentContentGrid
          key={emailAttachmentItem.attachmentUid}
          onClick={() => handleEmailAttachmentClick(emailAttachmentItem)}
        >
          <EmailAttachmentIconImage src={AttachmentIcon} alt="..." />
          <EmailAttachmentNameTypography>
            {emailAttachmentItem.name}
          </EmailAttachmentNameTypography>
        </EmailResponseAttachmentContentGrid>
      );
    });
  };

  const renderDialog = (dialogProps: any): ReactElement => {
    return <EmailAttachmentViewerDialog {...dialogProps} />;
  };

  return (
    <>
      {renderDialog({
        open: state.shouldDialogOpen,
        handleCloseBtnClick: dialogClose,
        isLoading: state.isDialogContentLoading,
        dialogTitle: state.dialogTitle,
        dialogContent: state.dialogContent,
        handleDownloadBtnClick: handleEmailAttachmentDownload,
        isMimeTypeUnsupported: state.isMimeTypeUnsupported,
      })}

      <EmailResponseDetailsContainerGrid
        isLastEmailThreadIndex={isLastEmailThreadIndex}
      >
        {/* {isEmailTabActive ? (
          <ResponseKeywordDivStyled>
            <ResponseKeyword>{t('ADMIN.EMAIL.RESPONSE')}</ResponseKeyword>
          </ResponseKeywordDivStyled>
        ) : null} */}

        <DateAndFromEmailDiv>
          <EmailDivStyled>
            <KeywordStyledSpan>{t('ADMIN.EMAIL.FROM')}</KeywordStyledSpan>
            <ValueStyledSpan>{fromEmail}</ValueStyledSpan>
          </EmailDivStyled>
          <DateStyledSpan>{emailReceivedFormattedDate}</DateStyledSpan>
        </DateAndFromEmailDiv>

        <EmailDivStyled>
          <KeywordStyledSpan>{t('ADMIN.EMAIL.TO')}</KeywordStyledSpan>
          <ValueStyledSpan>{toEmail}</ValueStyledSpan>
        </EmailDivStyled>

        {cc && cc?.length > 0 ? (
          <EmailDivStyled>
            <KeywordStyledSpan>{t('ADMIN.EMAIL.CC')}</KeywordStyledSpan>
            <ValueStyledSpan>{cc}</ValueStyledSpan>
          </EmailDivStyled>
        ) : null}

        {files && files.length > 0 && (
          <EmailResponseAttachmentWrapperGrid>
            {renderEmailAttachments(files)}
          </EmailResponseAttachmentWrapperGrid>
        )}

        {body && (
          <RichTextEmailResponseViewer
            issubjectviewer={'no'}
            value={body}
            modules={modules}
            formats={formats}
            readOnly={true}
          />
        )}
      </EmailResponseDetailsContainerGrid>
    </>
  );
};

export default EmailResponseTextViewer;
