import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { t } from 'i18next';
import {
  MRT_RowData as MRTRowData,
  MRT_Row as MRTRow,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table';
import {
  LoadingGrid,
  MenuItemStyled,
  MoreIconStyled,
  MoreWrapper,
  OverlayLoaderContainer,
  RowActionsWrapper,
  RowCaseStyleDiv,
  RowStyleDiv,
  RowStyledDiv,
  StatusRowLoaderContainer,
  StyledChip,
  TypeCellSubTextStyle,
  ViewButtonStyled,
} from './QueueTableStyle';
import { Box, Menu, Tooltip } from '@mui/material';
import { APP } from 'src/styles/variables';
import { produce } from 'immer';
import { formatDate, statusFormat } from '@modules/home/utils/Utils';
import moreSvgIcon from '@assets/images/More.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import FlagQueueItem from './flag-queue-item/FlagQueueItem';
import {
  PriorityTypes,
  QueueMoreOptions,
  QueueTaskStatusTypes,
} from '../queue-constants/QueueConstants';
import ChangeQueueItem from './change-queue-item/ChangeQueueItem';
import SearchableSelectInput from '@components/select-input/select-text-input/searchable-select-input/SearchableSelectInput';
import { useSnackbar } from 'notistack';
import {
  getAllQueueList,
  updateAssignedUser,
  getAllQueueUnclassifiedList,
  archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryId,
} from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import {
  AssignedUser,
  QueueParams,
  UpdateAssignedUserPayloadDto,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import {
  ERROR_CONSTANTS,
  QUEUE_SCREEN_TABS,
  SORT_ORDER,
  USER_TYPES,
} from '@constants/global-constants/constants';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { UserQueueMoreOptions } from '@modules/home/main/user/user-queue/user-queue-constants';
import AuthUtils from '@utils/AuthUtils';
import RedFlag from '@assets/images/RedFlag.svg';
import { QueueTableColumns } from './QueueTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import {
  changeTableName,
  clearUpdatedTaskStatuses,
  reloadQueueTable,
  setIsRefetchingNeeded,
  setParentQueueTabPathName,
  showBulkActionButton,
  showBulkChangeTypePopUp,
  showBulkReAssignDialogPopUp,
  taskCompleted,
  updateTaskStatusWhenAssigneeChanges,
} from '@modules/home/state/queue/QueueSlice';
import BulkReAssignUserDialog from './bulk-re-assign-user-dialog/BulkReAssignUserDialog';
import { NoDataFoundDivStyled } from 'src/styles/common/CommonStyles';
import { updateTaskStatusById } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import Loader from '@components/loaders/Loader';
import { ProfilePicRefType } from 'src/types/Types';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';

interface IQueueTableStateProps {
  isLoading: boolean;
  hoveredRow: string;
  isMenuOpen: boolean;
  showFlagQueueItem: boolean; // used for show flag queue item dialog in users portal
  showChangeQueueItem: boolean;
  selectedRow: string | null;
  disableHoverEffect: boolean;
  selectedRowId: string;
  totalRowCount: number;
  reloadGrid: boolean;
  userList: AssignedUser[] | [];
  networkErrorState: boolean;
  selectedRowTaskId: string | null;
  isBulkEditNeeded: boolean;
  shouldBulkEditDialogOpen: boolean;
  bulkUpdateIds: string[];
  selectedRowStateId: string;
  selectedRowStateNsa: string;
  selectedRowTaskCategory: string;
  selectedRowTaskType: string;
  isProfilePicFetching: boolean;
  isUpdatingTaskStatus: boolean;
  rowLoadingState: { [key: string]: boolean };
  selectedUser: { [key: string]: AssignedUser | undefined };
}

const initialQueueTableState: IQueueTableStateProps = {
  isLoading: true,
  hoveredRow: '',
  isMenuOpen: false,
  showFlagQueueItem: false, // used for show flag queue item dialog in users portal
  showChangeQueueItem: false,
  selectedRow: null,
  disableHoverEffect: false,
  selectedRowId: '',
  totalRowCount: 0,
  reloadGrid: false,
  userList: [],
  networkErrorState: false,
  selectedRowTaskId: null,
  isBulkEditNeeded: false,
  shouldBulkEditDialogOpen: false,
  bulkUpdateIds: [],
  selectedRowStateId: '',
  selectedRowStateNsa: '',
  selectedRowTaskCategory: '',
  selectedRowTaskType: '',
  isProfilePicFetching: true,
  isUpdatingTaskStatus: false,
  rowLoadingState: {},
  selectedUser: {},
};

interface IQueueTableProps {
  isRowSelectionNeeded?: boolean;
  isUserPortal?: boolean;
}

const QueueTable = (props: IQueueTableProps): ReactElement => {
  const { isRowSelectionNeeded = true, isUserPortal = false } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const selectedRowIdRef = useRef('');
  const userType = AuthUtils.getUserType();
  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({});
  const [queueTableState, setQueueTableState] = useState<IQueueTableStateProps>(
    initialQueueTableState,
  );

  const defaultSortOrder: MRTSortingState = [
    {
      id: location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      )
        ? SORT_ORDER.DATE
        : SORT_ORDER.DUE_DATE,
      desc: false,
    },
  ];

  const UnassignObj = {
    contactEmail: null,
    email: null,
    id: null,
    name: AdminConstants.QUEUE.UN_ASSIGN,
    profilePicId: null,
  };
  const UnassignedObj = {
    contactEmail: null,
    email: null,
    id: '1',
    name: 'Unassigned',
    profilePicId: null,
  };

  const {
    hoveredRow,
    showFlagQueueItem,
    showChangeQueueItem,
    isLoading,
    selectedRow,
    totalRowCount,
    disableHoverEffect,
    userList,
    networkErrorState,
    selectedRowTaskId,
    selectedRowStateId,
    selectedRowStateNsa,
    selectedRowTaskCategory,
    selectedRowTaskType,
    isProfilePicFetching,
    isUpdatingTaskStatus,
    rowLoadingState,
    selectedUser,
  } = queueTableState;

  const dispatch = useDispatch();

  const {
    fetchDataFnQueryKeyName,
    isRefetchingNeeded,
    showBulkReAssignDialog,
    showBulkChangeTypeDialog,
    showBulkActionMenuButton,
    queueSearchAndFilterOptions,
  } = useSelector((state: RootState) => state.queue);

  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});

  const setFetchProfilePicRef = (userId: string) => {
    fetchedProfilePicRef.current[userId] = true;
  };

  const moreStartItemList = [
    { id: 1, value: QueueMoreOptions.START_TASK },
    { id: 2, value: QueueMoreOptions.CHANGE_TYPE },
    { id: 3, value: QueueMoreOptions.ARCHIVE },
  ];

  const moreCompleteItemList = [
    { id: 1, value: QueueMoreOptions.COMPLETE_TASK },
    { id: 2, value: QueueMoreOptions.CHANGE_TYPE },
  ];

  const userQueueMoreItemListForInProgress = [
    { id: 1, value: UserQueueMoreOptions.MARK_COMPLETE },
    { id: 2, value: UserQueueMoreOptions.FLAG_ITEM },
  ];

  const userQueueMoreItemList = [
    { id: 1, value: UserQueueMoreOptions.REVIEW },
    { id: 2, value: UserQueueMoreOptions.FLAG_ITEM },
  ];

  const getMoreOptionList = (status: string) => {
    if (userType === USER_TYPES.USER) {
      if (status === QueueTaskStatusTypes.IN_PROGRESS) {
        return userQueueMoreItemListForInProgress;
      } else {
        return userQueueMoreItemList;
      }
    }

    if (status === QueueTaskStatusTypes.IN_PROGRESS) {
      return moreCompleteItemList;
    }

    if (
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueUnclassified.FullPath
    ) {
      if (status === QueueTaskStatusTypes.UNCLASSIFIED) {
        return moreStartItemList.filter(
          item => item.value !== QueueMoreOptions.START_TASK,
        );
      } else if (status === QueueTaskStatusTypes.FLAGGED) {
        const notRequiredForUnclassifiedUnFlagged = [
          QueueMoreOptions.START_TASK,
          QueueMoreOptions.ARCHIVE,
        ];

        return moreStartItemList.filter(item => {
          return !notRequiredForUnclassifiedUnFlagged.includes(item.value);
        });
      }
    }

    return moreStartItemList.filter(
      item => item.value !== QueueMoreOptions.ARCHIVE,
    );
  };

  const init = () => {
    dispatch(reloadQueueTable({ reloadQueueTableGrid: true }));
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueSearchAndFilterOptions]);

  useEffect(() => {
    if (Object.keys(rowSelection).length !== 0) {
      setQueueTableState(
        produce(draft => {
          draft.isBulkEditNeeded = true;
          draft.bulkUpdateIds = Object.keys(rowSelection);
        }),
      );

      const showBulkActionMenuButtonPayload = {
        showBulkActionMenuButton: true,
      };
      dispatch(showBulkActionButton(showBulkActionMenuButtonPayload));
    } else {
      setQueueTableState(
        produce(draft => {
          draft.isBulkEditNeeded = false;
          draft.bulkUpdateIds = [];
        }),
      );
      const isQueueUnclassifiedTab = location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      );
      if (isQueueUnclassifiedTab) {
        const showBulkChangeTypeDialogPayload = {
          showBulkChangeTypeDialog: false,
        };
        dispatch(showBulkChangeTypePopUp(showBulkChangeTypeDialogPayload));
      } else {
        const showBulkReAssignDialogPayload = {
          showBulkReAssignDialog: false,
        };
        dispatch(showBulkReAssignDialogPopUp(showBulkReAssignDialogPayload));
      }

      const showBulkActionMenuButtonPayload = {
        showBulkActionMenuButton: false,
      };
      dispatch(showBulkActionButton(showBulkActionMenuButtonPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  useEffect(() => {
    if (isRefetchingNeeded) {
      dispatch(setIsRefetchingNeeded({ data: false }));
      dispatch(changeTableName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchingNeeded]);

  const queueDetailsNavigationURL = (id: string) => {
    dispatch(
      setParentQueueTabPathName({
        parentQueueTabPathNameUrl: location.pathname,
      }),
    );
    const navigationUrl =
      userType === USER_TYPES.USER
        ? PrivateNavigationRoutes.User.ChildRoutes.Queue.ChildRoutes.QueueDetails.FullPath.replace(
            ':taskId',
            id,
          )
        : PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueDetails.FullPath.replace(
            ':taskId',
            id,
          );
    return navigate(navigationUrl);
  };

  const refreshQueueTableScreen = (refreshQueueTableGrid = false) => {
    const reloadQueueTableGridPayload = {
      reloadQueueTableGrid: refreshQueueTableGrid,
    };
    dispatch(reloadQueueTable(reloadQueueTableGridPayload));
    dispatch(changeTableName());
  };

  const resetSelectedRows = () => {
    setRowSelection({});
  };

  // get Queue All/ Unclassified list
  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    try {
      setQueueTableState(
        produce(draft => {
          draft.isLoading = true;
        }),
      );

      if (pageParam === 0) {
        dispatch(clearUpdatedTaskStatuses());
      }

      let searchAndFilterQueries = {};
      const searchAndFilterStateOptions = { ...queueSearchAndFilterOptions };

      if (searchAndFilterStateOptions?.nsaState) {
        if (
          searchAndFilterStateOptions.nsaState ===
          AdminConstants.USERS.NSA.toLowerCase()
        ) {
          searchAndFilterStateOptions.stateNsa =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        } else if (
          searchAndFilterStateOptions.nsaState ===
          AdminConstants.USERS.STATE.toLowerCase()
        ) {
          searchAndFilterStateOptions.stateNsa =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        } else if (searchAndFilterStateOptions.nsaState !== '') {
          searchAndFilterStateOptions.stateNsa =
            AdminConstants.USERS.STATE.toLowerCase();
          searchAndFilterStateOptions.stateId =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        }
      }
      searchAndFilterStateOptions.status =
        searchAndFilterStateOptions.taskStatus;
      delete searchAndFilterStateOptions.taskStatus;

      if (!searchAndFilterStateOptions.searchValue) {
        delete searchAndFilterStateOptions.searchKey;
      }

      (
        Object.entries(searchAndFilterStateOptions) as [string, string][]
      ).forEach(([key, value]: [string, string]) => {
        if (value) {
          searchAndFilterQueries = { ...searchAndFilterQueries, [key]: value };
        }
      });

      const sortOrder =
        sorting && sorting.length > 0 ? sorting[0] : defaultSortOrder[0];

      const payload: QueueParams = {
        offset: pageParam * fetchSize,
        limit: fetchSize,
        orderBy: sortOrder.id,
        orderByKey: sortOrder.desc === true ? SORT_ORDER.DESC : SORT_ORDER.ASC,
        ...searchAndFilterQueries,
      };

      const isQueueUnclassifiedTab = location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      );

      const res = isQueueUnclassifiedTab
        ? await getAllQueueUnclassifiedList(payload)
        : await getAllQueueList(payload);

      const { data } = res;

      setQueueTableState(
        produce(draft => {
          draft.totalRowCount = data?.count ?? 0;
          draft.isLoading = false;
          draft.networkErrorState = false;
        }),
      );

      const reloadQueueTableGridPayload = { reloadQueueTableGrid: false };
      dispatch(reloadQueueTable(reloadQueueTableGridPayload));

      return data.rows;
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      if (e.code === ERROR_CONSTANTS.ERR_NETWORK || e.statusCode === 500) {
        setQueueTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setQueueTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isLoading = false;
        }),
      );
    } finally {
      setQueueTableState(
        produce(draft => {
          draft.isLoading = false;
        }),
      );
    }
  };

  const handleUpdateTaskStatusById = async (row: MRTRow<MRTRowData>) => {
    if (row?.original?.status) {
      setQueueTableState(
        produce(draft => {
          draft.isLoading = true;
          draft.isUpdatingTaskStatus = true;
        }),
      );
      try {
        const payload = {
          status:
            row?.original?.status === QueueTaskStatusTypes.IN_PROGRESS
              ? QueueTaskStatusTypes.COMPLETED
              : row?.original?.status === QueueTaskStatusTypes.ASSIGNED ||
                row?.original?.status === QueueTaskStatusTypes.FLAGGED ||
                row?.original?.status === QueueTaskStatusTypes.UNASSIGNED
              ? QueueTaskStatusTypes.IN_PROGRESS
              : '',
        };
        const res = await updateTaskStatusById(row.original.id, payload);
        if (row?.original?.status === QueueTaskStatusTypes.IN_PROGRESS && res) {
          dispatch(taskCompleted({ isTaskCompleted: true }));
          refreshQueueTableScreen(true);
        } else {
          queueDetailsNavigationURL(row.original.id);
        }
        enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
      } catch (e) {
        const errMessage = getErrorMessage(e);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        setQueueTableState(
          produce(draft => {
            draft.isLoading = false;
            draft.isUpdatingTaskStatus = false;
          }),
        );
      }
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setQueueTableState(
      produce(draft => {
        draft.showFlagQueueItem = false;
        draft.showChangeQueueItem = false;
        draft.hoveredRow = '';
        draft.selectedRow = null;
      }),
    );
  };

  const handlePopOverOpenClose = (isModalOpen?: boolean, rowId?: string) => {
    setQueueTableState(
      produce(draft => {
        draft.disableHoverEffect = !!isModalOpen;
        draft.selectedRowId = isModalOpen ? rowId ?? '' : '';
      }),
    );
    selectedRowIdRef.current = isModalOpen ? rowId ?? '' : '';
  };

  const handleMoreClick = (
    event: React.MouseEvent<HTMLDivElement>,
    rowId: string,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setQueueTableState(
      produce(draft => {
        draft.selectedRow = rowId;
      }),
    );
  };

  const handleFlagQueueItem = (row: MRTRow<MRTRowData>) => {
    setQueueTableState(
      produce(draft => {
        draft.showFlagQueueItem = true;
        draft.selectedRowTaskId = row.original.id;
        draft.selectedRowStateId = row?.original?.stateId ?? '';
        draft.selectedRowStateNsa = row?.original?.nsaState ?? '';
      }),
    );
  };

  const handleChangeQueueItem = (row: MRTRow<MRTRowData>) => {
    setQueueTableState(
      produce(draft => {
        draft.showChangeQueueItem = true;
        draft.selectedRowTaskId = row.original.id;
        draft.selectedRowStateId = row?.original?.stateId ?? '';
        draft.selectedRowStateNsa = row?.original?.nsaState ?? '';
        draft.selectedRowTaskCategory = row?.original?.taskCategory ?? '';
        draft.selectedRowTaskType = row?.original?.taskType ?? '';
      }),
    );
  };

  const handleStartOrCompleteTask = (
    row: MRTRow<MRTRowData>,
    value: string,
  ) => {
    setMenuAnchorEl(null);
    handleUpdateTaskStatusById(row);
  };

  const handleReview = (row: MRTRow<MRTRowData>) => {
    queueDetailsNavigationURL(row?.original?.id ?? '');
  };
  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={`${column.columnDef.header}`}>
        <div>{column.columnDef.header}</div>
      </Tooltip>
    );
  };
  const renderCellValue = (nsaState: string, stateCode: string) => {
    if (nsaState.toLowerCase() === StateNsaAllStateEnum.NSA) {
      return (
        <div>
          <RowStyleDiv>{nsaState.toUpperCase()}</RowStyleDiv>
        </div>
      );
    } else if (nsaState.toLowerCase() === StateNsaAllStateEnum.STATE) {
      return (
        <div>
          <RowStyleDiv>{stateCode.toUpperCase()}</RowStyleDiv>
        </div>
      );
    }
  };

  const renderCaseIdCellValue = (rowData: {
    caseId?: string;
    status?: string;
  }) => {
    const { caseId = '', status } = rowData;
    const isFlagged = status === QueueTaskStatusTypes.FLAGGED;
    if (status) {
      return (
        <RowCaseStyleDiv>
          {isFlagged && <img src={RedFlag} alt="Flagged" />}
          <RowStyleDiv>{caseId ?? ''}</RowStyleDiv>
        </RowCaseStyleDiv>
      );
    }
  };
  const renderFlagQueueItem = () => {
    return (
      <FlagQueueItem
        isDialogOpen={showFlagQueueItem}
        handleCancel={handleCloseMenu}
        taskId={selectedRowTaskId || ''}
        refreshScreen={refreshQueueTableScreen}
      />
    );
  };

  const handleBulkChangeTypeClose = () => {
    dispatch(showBulkChangeTypePopUp({ showBulkChangeTypeDialog: false }));
  };

  const renderChangeQueueItem = () => {
    if (showBulkChangeTypeDialog) {
      return (
        <ChangeQueueItem
          isDialogOpen={showBulkChangeTypeDialog}
          handleCancel={handleBulkChangeTypeClose}
          bulkSelectedTaskIds={queueTableState.bulkUpdateIds}
          refreshScreen={refreshQueueTableScreen}
          resetSelectedRows={resetSelectedRows}
        />
      );
    } else {
      return (
        <ChangeQueueItem
          isDialogOpen={showChangeQueueItem}
          handleCancel={handleCloseMenu}
          taskId={selectedRowTaskId || ''}
          stateId={selectedRowStateId || ''}
          stateNsa={selectedRowStateNsa || ''}
          taskCategory={selectedRowTaskCategory || ''}
          taskType={selectedRowTaskType || ''}
          refreshScreen={refreshQueueTableScreen}
        />
      );
    }
  };

  const renderChip = (value: string) => {
    return <StyledChip label={value} variant="filled" />;
  };

  const renderTypeValue = (rowData: {
    taskCategory: string;
    taskType: string;
    status: string;
  }) => {
    const { taskCategory, taskType, status } = rowData;

    const isUnclassifiedUnflaggedTask =
      location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath &&
      status !== QueueTaskStatusTypes.FLAGGED;

    return (
      <div>
        <RowStyleDiv>
          {isUnclassifiedUnflaggedTask && taskType?.length === 0
            ? QUEUE_SCREEN_TABS.UNCLASSIFIED
            : taskType}
        </RowStyleDiv>
        <TypeCellSubTextStyle>
          {taskCategory.length > 0 ? `(${taskCategory})` : ''}
        </TypeCellSubTextStyle>
      </div>
    );
  };

  const handleAssignedUserChange = async (
    data: AssignedUser,
    rowId: string,
  ) => {
    try {
      setQueueTableState(prevState =>
        produce(prevState, draft => {
          draft.rowLoadingState[rowId] = true; // Mark the row as loading
        }),
      );

      const isTaskUnassigned =
        data?.name?.toLowerCase() ===
        AdminConstants.QUEUE.UN_ASSIGN.toLowerCase();

      const payload: UpdateAssignedUserPayloadDto = {
        userId: data?.id,
        ids: [rowId],
        ...(isTaskUnassigned && { isUnassigned: isTaskUnassigned }),
      };
      const res = await updateAssignedUser(payload);
      const AssignedStatus =
        data?.name?.toLowerCase() ===
        AdminConstants.QUEUE.UN_ASSIGN.toLowerCase()
          ? AdminConstants.QUEUE.UN_ASSIGNED
          : AdminConstants.QUEUE.ASSIGNED;
      dispatch(
        updateTaskStatusWhenAssigneeChanges({
          rowId,
          status: AssignedStatus,
        }),
      );

      if (data?.name?.toLowerCase() === UnassignObj.name.toLowerCase()) {
        setQueueTableState(
          produce(draft => {
            draft.selectedUser[rowId] = UnassignedObj;
          }),
        );
      } else {
        setQueueTableState(
          produce(draft => {
            draft.selectedUser[rowId] = data;
          }),
        );
      }

      enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueTableState(prevState =>
        produce(prevState, draft => {
          draft.rowLoadingState[rowId] = false;
        }),
      );
    }
  };

  const renderStatus = (value: string, row: any) => {
    const rowId = `${row?.original?.id || ''}`;

    const isTaskReassigningLoading = rowLoadingState[rowId];

    return (
      <StatusRowLoaderContainer>
        {isTaskReassigningLoading ? (
          <LoadingGrid>
            <Loader size={30} />
          </LoadingGrid>
        ) : row?.original?.status ? (
          statusFormat(row?.original?.status)
        ) : (
          ''
        )}
      </StatusRowLoaderContainer>
    );
  };

  const renderSearchableSelectInput = (value: AssignedUser, row: any) => {
    const rowId = `${row?.original?.id || ''}`;

    const taskId = row?.original?.id ?? '';

    const isUnclassified =
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueUnclassified.FullPath;

    const isUnFlagged = row?.original?.status !== QueueTaskStatusTypes.FLAGGED;

    const shouldNotRender = isUnclassified && isUnFlagged;

    if (shouldNotRender) return null;

    const isTaskReassigningLoading = rowLoadingState[rowId];

    const isSelectedUser = selectedUser[rowId];

    return isTaskReassigningLoading ? (
      <LoadingGrid>
        <Loader size={30} />
      </LoadingGrid>
    ) : (
      <div>
        <SearchableSelectInput
          options={userList}
          defaultOption={isSelectedUser ?? value}
          handlePopOverOpenClose={(isModalOpen?: boolean) => {
            handlePopOverOpenClose(isModalOpen, rowId);
          }}
          handleOnChange={(data: any) =>
            handleAssignedUserChange(data, row?.original?.id ?? '')
          }
          openDropdown={!!rowId && selectedRowIdRef.current === rowId}
          showProfilePic={isProfilePicFetching}
          shouldLoadDefaultProfilePic={true}
          shouldFetchUsersOptionsListByTaskId={true}
          taskId={taskId}
          fetchedProfilePicRef={fetchedProfilePicRef}
          setFetchProfilePicRef={setFetchProfilePicRef}
        />
      </div>
    );
  };

  const handleArchiveUnclassifiedUnFlaggedTaskById = async (
    row: MRTRow<MRTRowData>,
  ) => {
    try {
      const response =
        await archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryId(row.id);

      enqueueSnackbar(response.data.message, SnackBarConfig.getSuccess());
      dispatch(changeTableName());
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const renderMoreMenu = (row: MRTRow<MRTRowData>) => {
    const moreOptionList = getMoreOptionList(row?.original?.status ?? '');

    return (
      <Menu
        slotProps={{
          paper: {
            style: {
              backgroundColor: APP.PALETTE.COMMON.BLACK,
              width: '12.5rem',
              flexShrink: 0,
            },
          },
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onMouseLeave={() => {
          setMenuAnchorEl(null);
        }}
        onClose={handleCloseMenu}
      >
        {moreOptionList.map((item, index) => {
          return (
            <MenuItemStyled
              key={item.id}
              sx={
                index === moreOptionList.length - 1
                  ? { borderBottom: 'none !important' }
                  : {}
              }
              onClick={() =>
                item.value === QueueMoreOptions.CHANGE_TYPE
                  ? handleChangeQueueItem(row)
                  : item.value === UserQueueMoreOptions.FLAG_ITEM
                  ? handleFlagQueueItem(row)
                  : item.value === QueueMoreOptions.START_TASK ||
                    item.value === QueueMoreOptions.COMPLETE_TASK
                  ? handleStartOrCompleteTask(row, item.value)
                  : item.value === UserQueueMoreOptions.REVIEW
                  ? handleReview(row)
                  : item.value === UserQueueMoreOptions.MARK_COMPLETE
                  ? handleUpdateTaskStatusById(row)
                  : item.value === UserQueueMoreOptions.ARCHIVE
                  ? handleArchiveUnclassifiedUnFlaggedTaskById(row)
                  : ''
              }
            >
              {item.value}
            </MenuItemStyled>
          );
        })}
      </Menu>
    );
  };

  const renderRowActionsSection = (row: MRTRow<MRTRowData>) => {
    return (
      <RowActionsWrapper>
        {hoveredRow ===
          (row?.original?.caseId || '') + (row?.original?.id || '') && (
          <>
            <ViewButtonStyled
              variant="outlined"
              onClick={() => {
                if (userType === USER_TYPES.USER) {
                  if (
                    row?.original?.status === QueueTaskStatusTypes.IN_PROGRESS
                  ) {
                    queueDetailsNavigationURL(row?.original?.id ?? '');
                  } else {
                    handleUpdateTaskStatusById(row);
                  }
                } else {
                  queueDetailsNavigationURL(row?.original?.id ?? '');
                }
              }}
            >
              {userType != USER_TYPES.USER
                ? t('ADMIN.QUEUE_TABS.VIEW')
                : row?.original?.status === QueueTaskStatusTypes.IN_PROGRESS
                ? t('ADMIN.QUEUE_TABS.CONTINUE')
                : t('ADMIN.QUEUE_TABS.START')}
            </ViewButtonStyled>
            <MoreWrapper
              data-row-id={row.id}
              onClick={e => handleMoreClick(e, row.id)}
            >
              <MoreIconStyled src={moreSvgIcon} alt="more-icon" />
            </MoreWrapper>
            {selectedRow === row.id && renderMoreMenu(row)}
          </>
        )}
      </RowActionsWrapper>
    );
  };

  const columnsData: MRTColumnDef<MRTRowData>[] = useMemo(
    () => {
      const baseColumns: MRTColumnDef<MRTRowData>[] = [
        {
          accessorFn: row => ({
            caseId: row?.caseId ?? '',
            status: row?.status ?? '',
          }),
          accessorKey: QueueTableColumns.caseId,
          header: t('QUEUE_TABLE.CASE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          Cell: ({ renderedCellValue }: any) =>
            renderCaseIdCellValue(renderedCellValue),
          grow: true,
          size: 130,
        },
        {
          accessorKey: QueueTableColumns.taskType,
          header: t('QUEUE_TABLE.TYPE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            taskCategory: row?.taskCategory ?? '',
            taskType: row?.taskType ?? '',
            status: row?.status ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderTypeValue(renderedCellValue),
          grow: true,
          size: 220,
        },
        {
          accessorKey: QueueTableColumns.customerName,
          header: t('QUEUE_TABLE.CUSTOMER'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>{row?.customerName ?? ''}</RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 160,
        },
        {
          accessorKey: QueueTableColumns.nsaState,
          header: t('QUEUE_TABLE.NSA_STATE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row =>
            renderCellValue(row?.nsaState ?? '', row?.stateCode ?? ''),
          grow: true,
          size: 130,
        },
        {
          accessorKey: QueueTableColumns.payorName,
          header: t('QUEUE_TABLE.PAYOR'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>{row?.payorName ?? ''}</RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 180,
        },
        {
          accessorKey: QueueTableColumns.priority,
          header: t('QUEUE_TABLE.PRIORITY'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>
                {row?.priority ? renderChip(row.priority) : ''}
              </RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 110,
        },
        {
          accessorKey:
            location.pathname ===
            PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
              .QueueUnclassified.FullPath
              ? QueueTableColumns.date
              : QueueTableColumns.dueDate,
          header:
            location.pathname ===
            PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
              .QueueUnclassified.FullPath
              ? t('QUEUE_TABLE.DATE')
              : t('QUEUE_TABLE.DUE_DATE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <RowStyledDiv>
              {location.pathname ===
              PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                .QueueUnclassified.FullPath
                ? row?.date
                  ? formatDate(row.date)
                  : ''
                : row?.dueDate
                ? formatDate(row.dueDate)
                : ''}
            </RowStyledDiv>
          ),
          grow: true,
          size: 120,
        },
        {
          accessorKey: QueueTableColumns.status,
          header: t('QUEUE_TABLE.STATUS'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => row?.status,
          Cell: ({ renderedCellValue, row }: any) =>
            renderStatus(renderedCellValue, row),
          grow: true,
          size: 110,
        },
      ];

      // Conditionally add the "Assigned" column based on userType
      if (userType !== USER_TYPES.USER) {
        baseColumns.push({
          accessorKey: QueueTableColumns.assignedUser,
          header: t('QUEUE_TABLE.ASSIGNED'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => row?.assignedUser,
          Cell: ({ renderedCellValue, row }: any) =>
            renderSearchableSelectInput(renderedCellValue, row),
          grow: true,
          size: 320,
        });
      }

      // Conditionally add the "Reason" column based on unclassified queue tab
      if (
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath
      ) {
        const statusColumn: MRTColumnDef<MRTRowData> = {
          accessorKey: QueueTableColumns.statusReason,
          header: t('QUEUE_TABLE.STATUS_REASON'),
          accessorFn: row => row?.statusReason ?? '',
          Cell: ({ renderedCellValue }: any) => (
            <RowStyledDiv>{renderedCellValue}</RowStyledDiv>
          ),
          grow: true,
          size: 150,
          enableSorting: false,
        };

        // Adding "Reason" column at a specific index
        baseColumns.splice(8, 0, statusColumn);
      }

      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userType,
      rowLoadingState,
      // userList
    ],
  );

  // bulk reassign user section starts

  const handleCancelBulkReAssignUser = () => {
    dispatch(showBulkReAssignDialogPopUp({ showBulkReAssignDialog: false }));
  };

  const renderBulkReAssignUserDialog = () => {
    return (
      <BulkReAssignUserDialog
        handleCancel={handleCancelBulkReAssignUser}
        isDialogOpen={showBulkActionMenuButton}
        BulkSelectedTaskIds={queueTableState.bulkUpdateIds}
        refreshScreen={refreshQueueTableScreen}
        resetSelectedRows={resetSelectedRows}
        fetchedProfilePicRef={fetchedProfilePicRef}
        setFetchProfilePicRef={setFetchProfilePicRef}
      />
    );
  };

  return (
    <Box>
      {isUpdatingTaskStatus ? (
        <OverlayLoaderContainer>
          <Loader size={40} />
        </OverlayLoaderContainer>
      ) : null}
      {networkErrorState ? (
        <NoDataFoundDivStyled>{t('NO_DATA_FOUND')}</NoDataFoundDivStyled>
      ) : (
        <ServerSideDataGrid
          isUserPortal={isUserPortal}
          fetchDataFnQueryKeyName={fetchDataFnQueryKeyName}
          fetchDataFn={fetchData}
          dataFetchSize={
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
          }
          totalRowsCount={totalRowCount}
          columns={columnsData}
          state={{ isLoading }}
          enableBottomToolbar={false}
          enableRowSelection={isRowSelectionNeeded}
          muiTableBodyRowProps={({ row }) => ({
            onMouseEnter: () => {
              if (disableHoverEffect) return;
              setQueueTableState(
                produce(draft => {
                  draft.hoveredRow =
                    (row?.original?.caseId || '') + (row?.original?.id || '');
                }),
              );
            },
            onMouseLeave: () => {
              if (disableHoverEffect) return;
              setQueueTableState(
                produce(draft => {
                  draft.hoveredRow = ' ';
                }),
              );
            },

            sx: {
              backgroundColor:
                row.original?.status === QueueTaskStatusTypes.FLAGGED
                  ? APP.PALETTE.BACKGROUND.GRID_PINK_BACKGROUND
                  : null,
              borderLeft:
                row.original?.priority === PriorityTypes.LOW
                  ? `5px solid ${APP.PALETTE.BACKGROUND.CHIP_GRAY}`
                  : row.original?.priority === PriorityTypes.HIGH
                  ? `5px solid ${APP.PALETTE.BACKGROUND.CHIP_RED}`
                  : row.original?.priority === PriorityTypes.MEDIUM
                  ? `5px solid ${APP.PALETTE.BACKGROUND.CHIP_ORANGE}`
                  : 'none', // Default color
            },
          })}
          muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
          }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              size: 200,
              grow: false,
              header: '',
            },
          }}
          enableRowActions={true}
          renderRowActions={({ row, table }) => renderRowActionsSection(row)}
          sortDescFirst={true}
          enableTableFooter={false}
          getRowId={row => row?.id ?? ''}
          onRowSelectionChange={setRowSelection}
          rowSelection={rowSelection}
        />
      )}
      {showFlagQueueItem && renderFlagQueueItem()}
      {showChangeQueueItem ||
      (showBulkChangeTypeDialog && showBulkActionMenuButton)
        ? renderChangeQueueItem()
        : null}
      {showBulkReAssignDialog &&
        showBulkActionMenuButton &&
        renderBulkReAssignUserDialog()}
    </Box>
  );
};

export default QueueTable;
