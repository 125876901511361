import { baseUrl } from '../../common/ApiEndPoint';

// emails api urls
const emailsBaseUrl = `${baseUrl}/emails`;
export const getEmailDetailsByIdUrl = `${emailsBaseUrl}/:id`;
export const getEmailIdentifiersByIdUrl = `${emailsBaseUrl}/:id/identifiers`;
export const getAllEmailsUrl = `${emailsBaseUrl}/all`;
export const receiveAndStoreEmailsInOurSystemUrl = `${emailsBaseUrl}/receive-email`;
export const getEmailThreadByIdUrl = `${emailsBaseUrl}/:id/thread`;
export const sendEmailReplyByIdUrl = `${emailsBaseUrl}/reply`;
export const sendEmailReplyAllByIdUrl = `${emailsBaseUrl}/replyAll`;
export const getEmailRecipientsByIdUrl = `${emailsBaseUrl}/:id/recipients`;

// training-email api urls
const trainingEmailsBaseUrl = `${baseUrl}/training-emails`;
export const getAllEmailSettingsURL = `${trainingEmailsBaseUrl}/all`;
export const getEmailSettingsDetailURL = `${trainingEmailsBaseUrl}`;
export const updateEmailSettingsDetailURL = `${trainingEmailsBaseUrl}`;
