import SideNavBar from '@components/side-nav-bar/SideNavBar';
import {
  PrivateNavigationRoutes,
  PRODUCTION_ENV,
} from '@constants/RouteConstants';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import NotFound from '@modules/not-found/NotFound';
import Dashboard from '@modules/home/main/admin/dashboard/Dashboard';
import Emails from '@modules/home/main/admin/emails/Emails';
import Profile from '@modules/home/main/admin/profile/Profile';
import Queue from '@modules/home/main/admin/queue/Queue';
import Users from '@modules/home/main/admin/users/Users';
import QueueHistory from '@modules/home/main/admin/queue/queue-history/QueueHistory';
import QueueAssignments from '@modules/home/main/admin/queue/queue-assignments/QueueAssignments';
import QueueAssignmentsByUser from '@modules/home/main/admin/queue/queue-assignments/queue-assignments-by-user/QueueAssignmentsByUser';
import UsersSettings from '@modules/home/main/admin/users/users-settings/UsersSettings';
import ActiveUsers from '@modules/home/main/admin/users/active-users/ActiveUsers';
import InactiveUsers from '@modules/home/main/admin/users/inactive-users/InactiveUsers';
import UserQueue from '@modules/home/main/user/user-queue/UserQueue';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { UserConstants } from '@constants/user-constants/UserConstants';
import TopNavBar from '@components/top-nav-bar/TopNavBar';
import UserHistory from '@modules/home/main/user/user-history/UserHistory';
import QueueTable from '@modules/home/main/admin/queue/queue-table/QueueTable';
import QueueDetails from '@modules/home/main/admin/queue/queue-details/QueueDetails';
import QueueAI from '@modules/home/main/admin/queue/queue-ai/QueueAI';
import QueueUnclassified from '@modules/home/main/admin/queue/queue-unclassified/QueueUnclassified';
import EmailDetails from '@modules/home/main/admin/emails/email-details/EmailDetails';
import EmailsTable from '@modules/home/main/admin/emails/emails-table/EmailsTable';
import QueueAssignmentsByType from '@modules/home/main/admin/queue/queue-assignments/queue-assignments-by-type/QueueAssignmentsByType';
import EmailSettings from '@modules/home/main/admin/email-settings/EmailSettings';
import EmailSettingsTable from '@modules/home/main/admin/email-settings/email-settings-table/EmailSettingsTable';
import EmailSettingsDetails from '@modules/home/main/admin/email-settings/email-settings-details/EmailSettingsDetails';
import LandingScreen from '@modules/landing/Landing';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { UserProfileContainer } from '@modules/home/main/user/user-profile/UserProfileStyle';
import { config } from '@environment/environment';

const PrivateRoutes = (): JSX.Element => {
  const currentRole = useSelector((state: RootState) => state.auth.userType);
  return (
    <Router>
      <Routes>
        <Route
          path={PrivateNavigationRoutes.Landing.Path}
          element={<LandingScreen />}
        />
        {currentRole === AdminConstants.ADMIN ? (
          <Route
            path={PrivateNavigationRoutes.Home.Path}
            element={<Navigate to={PrivateNavigationRoutes.Admins.Path} />}
          />
        ) : currentRole === UserConstants.USER ? (
          <Route
            path={PrivateNavigationRoutes.Home.Path}
            element={<Navigate to={PrivateNavigationRoutes.User.Path} />}
          />
        ) : null}

        {/**
         *
         * Admin Routes starts
         *
         **/}
        {currentRole === AdminConstants.ADMIN ? (
          <Route
            path={PrivateNavigationRoutes.Admins.Path}
            element={
              <Navigate
                to={PrivateNavigationRoutes.Admins.ChildRoutes.Dashboard.Path}
              />
            }
          />
        ) : null}
        {currentRole === AdminConstants.ADMIN ? (
          <Route
            path={PrivateNavigationRoutes.Admins.Path}
            element={<SideNavBar />}
          >
            {/* Dashboard route starts */}
            <Route
              path={PrivateNavigationRoutes.Admins.ChildRoutes.Dashboard.Path}
              element={<Dashboard />}
            />
            {/* Dashboard route ends */}

            {/* Queue routes starts */}
            <Route
              path={PrivateNavigationRoutes.Admins.ChildRoutes.Queue.Path}
              element={<Queue />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={
                      PrivateNavigationRoutes.Admins.ChildRoutes.Queue
                        .ChildRoutes.QueueTable.Path
                    }
                  />
                }
              />

              {/* Queue Tabs routes starts */}
              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueTable.Path
                }
                element={<QueueTable />}
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueDetails.Path
                }
                element={<QueueDetails />}
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueHistory.Path
                }
                element={<QueueHistory />}
              />

              {/* Queue Assignments routes starts */}
              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueAssignments.Path
                }
                element={<QueueAssignments />}
              >
                <Route
                  index
                  element={
                    <Navigate
                      to={
                        PrivateNavigationRoutes.Admins.ChildRoutes.Queue
                          .ChildRoutes.QueueAssignments.ChildRoutes
                          .QueueAssignmentsByType.Path
                      }
                    />
                  }
                />

                <Route
                  path={
                    PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                      .QueueAssignments.ChildRoutes.QueueAssignmentsByType.Path
                  }
                  element={<QueueAssignmentsByType />}
                />
                <Route
                  path={
                    PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                      .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.Path
                  }
                  element={<QueueAssignmentsByUser />}
                />
              </Route>
              {/* Queue Assignments routes ends */}

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueQueueAI.Path
                }
                element={<QueueAI />}
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
                    .QueueUnclassified.Path
                }
                element={<QueueUnclassified />}
              />
              {/* Queue Tabs routes ends */}
            </Route>
            {/* Queue routes ends */}

            {/* Users routes starts */}
            <Route
              path={PrivateNavigationRoutes.Admins.ChildRoutes.Users.Path}
              element={<Users />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={
                      PrivateNavigationRoutes.Admins.ChildRoutes.Users
                        .ChildRoutes.ActiveUsers.Path
                    }
                  />
                }
              />
              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
                    .ActiveUsers.Path
                }
                element={<ActiveUsers />}
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
                    .InactiveUsers.Path
                }
                element={<InactiveUsers />}
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
                    .UsersSettings.Path
                }
                element={<UsersSettings />}
              />
            </Route>
            {/* Users routes ends */}

            {/* Emails routes starts */}
            <Route
              path={PrivateNavigationRoutes.Admins.ChildRoutes.Emails.Path}
              element={<Emails />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={
                      PrivateNavigationRoutes.Admins.ChildRoutes.Emails
                        .ChildRoutes.EmailsTable.Path
                    }
                  />
                }
              />

              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes
                    .EmailsTable.Path
                }
                element={<EmailsTable />}
              />
              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes
                    .EmailDetails.Path
                }
                element={<EmailDetails />}
              />
            </Route>

            {/* Emails routes ends */}

            {/* Email Settings routes starts */}
            {config.BUILD_ENV !== PRODUCTION_ENV ? (
              <Route
                path={
                  PrivateNavigationRoutes.Admins.ChildRoutes.EmailSettings.Path
                }
                element={<EmailSettings />}
              >
                <Route
                  index
                  element={
                    <Navigate
                      to={
                        PrivateNavigationRoutes.Admins.ChildRoutes.EmailSettings
                          .ChildRoutes.EmailSettingsTable.Path
                      }
                    />
                  }
                />

                <Route
                  path={
                    PrivateNavigationRoutes.Admins.ChildRoutes.EmailSettings
                      .ChildRoutes.EmailSettingsTable.Path
                  }
                  element={<EmailSettingsTable />}
                />
                <Route
                  path={
                    PrivateNavigationRoutes.Admins.ChildRoutes.EmailSettings
                      .ChildRoutes.EmailSettingsDetails.Path
                  }
                  element={<EmailSettingsDetails />}
                />
              </Route>
            ) : null}
            {/* Email Settings routes end  */}

            {/* Profile routes starts */}
            <Route
              path={PrivateNavigationRoutes.Admins.ChildRoutes.Profile.Path}
              element={<Profile />}
            />
            {/* Profile routes ends */}
          </Route>
        ) : null}

        {/**
         *
         * Admin Routes ends
         *
         **/}

        {/**
         *
         * User Routes starts
         *
         **/}
        {currentRole === UserConstants.USER ? (
          <Route
            path={PrivateNavigationRoutes.User.Path}
            element={
              <Navigate
                to={PrivateNavigationRoutes.User.ChildRoutes.Queue.Path}
              />
            }
          />
        ) : null}
        {currentRole === UserConstants.USER ? (
          <Route
            path={PrivateNavigationRoutes.User.Path}
            element={<TopNavBar />}
          >
            <Route
              path={PrivateNavigationRoutes.User.ChildRoutes.Queue.Path}
              element={<UserQueue />}
            />
            <Route
              path={
                PrivateNavigationRoutes.User.ChildRoutes.Queue.ChildRoutes
                  .QueueDetails.FullPath
              }
              element={<QueueDetails />}
            />

            <Route
              path={PrivateNavigationRoutes.User.ChildRoutes.History.Path}
              element={<UserHistory />}
            />
            <Route
              path={PrivateNavigationRoutes.User.ChildRoutes.Profile.Path}
              element={
                <UserProfileContainer>
                  <Profile />
                </UserProfileContainer>
              }
            />
          </Route>
        ) : null}
        {/**
         *
         * User Routes ends
         *
         **/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default PrivateRoutes;
