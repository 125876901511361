import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { APP } from 'src/styles/variables';
import { SubTextContainer } from './NavigatingBackConfirmationDailogStyle';

export interface INavigatingBackConfirmationDialog {
  isDialogOpen: boolean;
  onSubmitCloseDialog: () => void;
  onCancel: () => void;
}

const NavigatingBackConfirmationDialog = (
  navigatingBackConfirmationDialogProps: INavigatingBackConfirmationDialog,
): ReactElement => {
  const { isDialogOpen, onCancel, onSubmitCloseDialog } =
    navigatingBackConfirmationDialogProps;

  const { handleSubmit } = useForm({});

  const renderDialogContent = () => {
    return (
      <SubTextContainer>
        {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.NAVIGATING_BACK_DIALOG_CONTENT')}
      </SubTextContainer>
    );
  };

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onCancel}
      titleText={t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.GO_BACK')}
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText=""
      dialogContent={renderDialogContent()}
      submitButtonText={t('ADMIN.USERS_TAB.YES')}
      cancelButtonText={t('ADMIN.USERS_TAB.NO')}
      onSubmit={handleSubmit(onSubmitCloseDialog)}
    />
  );
};

export default NavigatingBackConfirmationDialog;
