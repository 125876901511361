import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EmailSearchAndFilterPayloadType, EmailStateType } from './EmailTypes';

const initialState: EmailStateType = {
  emailIdForReplyForm: '',
  emailSearchAndFilterOptions: {},
  toEmailAddress: '',
  fromEmailAddress: '',
  ccEmailAddress: '',
  emailReplyBody: '',
  emailReplyAttachments: [],
  isSendingReplyEmail: false,
};

const EmailSlice = createSlice({
  initialState,
  name: 'emails',
  reducers: {
    setEmailSearchAndFilterValues(
      state,
      action: PayloadAction<EmailSearchAndFilterPayloadType>,
    ) {
      state.emailSearchAndFilterOptions = action.payload.data;
    },
    updateEmailIdForReplyForm(state, action: PayloadAction<string>) {
      state.emailIdForReplyForm = action.payload;
    },
    updateFromEmailAddress(state, action: PayloadAction<string>) {
      state.fromEmailAddress = action.payload;
    },
    updateToEmailAddress(state, action: PayloadAction<string>) {
      state.toEmailAddress = action.payload;
    },
    updateCcEmailAddress(state, action: PayloadAction<string>) {
      state.ccEmailAddress = action.payload;
    },
    updateEmailReplyBody(state, action: PayloadAction<string>) {
      state.emailReplyBody = action.payload;
    },
    updateEmailReplyAttachments(state, action: PayloadAction<File[]>) {
      state.emailReplyAttachments = action.payload;
    },
    resetEmailReplyForm(state) {
      state.emailIdForReplyForm = '';
      state.toEmailAddress = '';
      state.ccEmailAddress = '';
      state.fromEmailAddress = '';
      state.emailReplyBody = '';
      state.emailReplyAttachments = [];
    },
    setIsSendingReplyEmail(state, action: PayloadAction<boolean>) {
      state.isSendingReplyEmail = action.payload;
    },
  },
});

export const {
  setEmailSearchAndFilterValues,
  updateEmailIdForReplyForm,
  updateFromEmailAddress,
  updateToEmailAddress,
  updateCcEmailAddress,
  updateEmailReplyAttachments,
  updateEmailReplyBody,
  resetEmailReplyForm,
  setIsSendingReplyEmail,
} = EmailSlice.actions;

export default EmailSlice.reducer;
