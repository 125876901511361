export const config = {
  // Check with the lead dev and update the variables based on the requirement
  REQ_TIME_OUT: 90000,
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  NODE_ENV: process.env.NODE_ENV,
  BUILD_ENV: process.env.REACT_APP_ENV,
  ENCRYPT_LOCAL_STORAGE_KEY: process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE_KEY,
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID ?? '',
      authority: `${process.env.REACT_APP_MICROSOFT_BASE_URL}/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
    system: {},
  },
};
