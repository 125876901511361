export const BASE_ROUTE = 'halomd';
export const PRODUCTION_ENV = 'production';

export const PrivateNavigationRoutes = {
  Landing: {
    Path: 'landing',
    FullPath: '/landing',
  },
  Admins: {
    Path: 'admins',
    FullPath: '/admins',
    ChildRoutes: {
      Dashboard: {
        Path: 'dashboard',
        FullPath: '/admins/dashboard',
      },
      Queue: {
        Path: 'queue',
        FullPath: '/admins/queue',
        ChildRoutes: {
          QueueTable: {
            Path: 'table',
            FullPath: '/admins/queue/table',
          },
          QueueDetails: {
            Path: ':taskId/details',
            FullPath: '/admins/queue/:taskId/details',
          },
          QueueHistory: {
            Path: 'history',
            FullPath: '/admins/queue/history',
          },
          QueueAssignments: {
            Path: 'assignments',
            FullPath: '/admins/queue/assignments',
            ChildRoutes: {
              QueueAssignmentsByType: {
                Path: 'type',
                FullPath: '/admins/queue/assignments/type',
              },
              QueueAssignmentsByUser: {
                Path: 'user',
                FullPath: '/admins/queue/assignments/user',
              },
            },
          },
          QueueQueueAI: {
            Path: 'queueAI',
            FullPath: '/admins/queue/queueAI',
          },
          QueueUnclassified: {
            Path: 'unclassified',
            FullPath: '/admins/queue/unclassified',
          },
        },
      },

      Users: {
        Path: 'users',
        FullPath: '/admins/users',
        ChildRoutes: {
          ActiveUsers: {
            Path: 'active',
            FullPath: '/admins/users/active',
          },
          InactiveUsers: {
            Path: 'inactive',
            FullPath: '/admins/users/inactive',
          },
          UsersSettings: {
            Path: ':userId/settings',
            FullPath: '/admins/users/:userId/settings',
          },
          UsersPUAddUser: {
            Path: 'add-user',
            FullPath: '/admins/users/add-user',
          },
        },
      },

      Emails: {
        Path: 'emails',
        FullPath: '/admins/emails',
        ChildRoutes: {
          EmailsTable: {
            Path: 'table',
            FullPath: '/admins/emails/table',
          },
          EmailDetails: {
            Path: ':emailMessageId/details',
            FullPath: '/admins/emails/:emailMessageId/details',
          },
        },
      },

      EmailSettings: {
        Path: 'email-settings',
        FullPath: '/admins/email-settings',
        ChildRoutes: {
          EmailSettingsTable: {
            Path: 'table',
            FullPath: '/admins/email-settings/table',
          },
          EmailSettingsDetails: {
            Path: ':emailID/details',
            FullPath: '/admins/email-settings/:emailId/details',
          },
        },
      },

      Profile: {
        Path: 'profile',
        FullPath: '/admins/profile',
      },
    },
  },
  User: {
    Path: 'user',
    FullPath: '/user',
    ChildRoutes: {
      Queue: {
        Path: 'queue',
        FullPath: '/user/queue',
        ChildRoutes: {
          QueueDetails: {
            Path: ':taskId/details',
            FullPath: '/user/queue/:taskId/details',
          },
        },
      },

      History: {
        Path: 'history',
        FullPath: '/user/history',
      },
      Profile: {
        Path: 'profile',
        FullPath: '/user/profile',
      },
    },
  },
  Home: {
    Path: '/',
    FullPath: '/',
  },
};

export const PublicNavigationRoutes = {
  Login: { Path: 'local-sign-in', FullPath: '/local-sign-in' },
};
