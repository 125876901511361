import FormDialog from '@components/dialog/form-dialog/FormDialog';
import SelectSearchInput from '@components/select-input/select-search-input/SelectSearchInput';
import { LabelStyled } from '@components/select-input/select-text-input/SelectTextInputStyle';
import {
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// import CrossIcon from '@images/Close.svg';
import SearchIcon from '@images/Search.svg';
import ArrowSolidIcon from '@images/ArrowSolid.svg';
import CrossIcon from '@assets/images/Close.svg';
import { produce } from 'immer';
import {
  CheckboxStyled,
  CustomImg,
  DialogContentContainer,
  DividerStyled,
  FormAfterSelectingUserFromAzure,
  QueueAssignmentOptionContainer,
  QueueAssignmentsContainer,
  QueueAssignmentsOptionsContainer,
  QueueAssignmentsTitle,
  RemoveSelectedUser,
  RoleSelectionDropdown,
  RoleSelectionDropdownContainer,
  SearchAndSelectUserDropDownContainer,
  SelectedUserEmailId,
  SelectedUserInfo,
  SelectedUserInfoContainer,
  SelectedUserName,
  SelectedUserPhoneNumber,
  SelectStateListContainer,
  SelectStateNsaDropdownContainer,
  SelectStateNsaDropdownTitle,
  SelectStateNsaDropdownWrapper,
  StateCodeChipsContainer,
  StateNsaContainer,
  StateNsaTitle,
  StyledCustomChip,
  TabFilterContainer,
  TitleAndRoleLabel,
  TitleAndRoleSelectionDropdownContainer,
  TitleSelectionDropdown,
  TitleSelectionDropdownContainer,
  MainLoaderContainer,
} from './UsersPuAddNewUserDialogStyle';
import { APP } from 'src/styles/variables';
import SelectTextInput from '@components/select-input/select-text-input/SelectTextInput';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import TabFilter from '@components/tabs/tab-filter/TabFilter';
import {
  addUser,
  getAllUsersByServicePrincipalIdFromAzure,
  getUserByServicePrincipalIdFromAzure,
} from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import {
  GetAllUsersByServicePrincipalIdFromAzureQueryParam,
  GetADUsersByServicePrincipalIdDataRow,
  ICategoryOption,
  AddUserPayloadDto,
} from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import Loader from '@components/loaders/Loader';
import { GetAllRolesDataRow } from 'src/services/service-handlers/private/roles-handlers/RolesServiceMapper';
import {
  getAllRoles,
  getAllTitles,
} from 'src/services/service-handlers/private/roles-handlers/RolesPrivateService';
import { getAllStates } from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import {
  GetAllStatesQueryParam,
  GetAllStatesDataRow,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import {
  getAllAssignmentLevels,
  getAllTaskCategories,
} from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesPrivateService';
import {
  GetAllAssignmentLevelsDataRow,
  GetAllTaskCategoriesDataRow,
} from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesServiceMapper';
import { SEARCH_DEBOUNCE_DELAY_IN_MS } from '@constants/global-constants/constants';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { getErrorMessage } from '@utils/ErrorUtils';

interface IUsersPuAddNewUserDialogProps {
  handleClose: () => void;
  onSubmitDialogClose: () => void;
  isDialogOpen: boolean;
}

interface ISelectedUserInfoFromAzure {
  name: string;
  emailId: string;
  phone?: string;
  azureId: string;
}

interface IStateCode {
  id: string;
  stateCode: string;
}

const mapDropdownOptionToDropdownOptionsType = (
  optionsList: GetAllRolesDataRow[],
) => {
  return optionsList.map(option => ({
    value: option.id,
    label: option.label,
  }));
};

const selectStateNsaDropdownOption = [
  {
    value: StateNsaAllStateEnum.STATE,
    label: AdminConstants.USERS.STATE,
  },
  {
    value: StateNsaAllStateEnum.NSA,
    label: AdminConstants.USERS.NSA,
  },
];

interface IAddNewUserFormState {
  searchUserInput: string;
  title: string;
  role: string;
  stateNsa: string;
  isAllState: boolean;
  searchStateInput: string;
  stateIds: string[];
  categories: ICategoryOption[];
}

const initialAddNewUserFormState: IAddNewUserFormState = {
  searchUserInput: '',
  title: '',
  role: '',
  stateNsa: '',
  isAllState: false,
  searchStateInput: '',
  stateIds: [],
  categories: [],
};

const UsersPuAddNewUserDialog = (
  usersPuAddNewUserDialogProps: IUsersPuAddNewUserDialogProps,
): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useForm({});
  const navigate = useNavigate();
  const { isDialogOpen, handleClose, onSubmitDialogClose } =
    usersPuAddNewUserDialogProps;
  // const [userError, setUserError] = useState('');
  const [addNewUserFormState, setAddNewUserFormState] =
    useState<IAddNewUserFormState>({
      searchUserInput: '',
      title: '',
      role: '',
      stateNsa: '',
      isAllState: false,
      searchStateInput: '',
      stateIds: [],
      categories: [],
    });

  const [selectedAzureUser, setSelectedAzureUser] =
    useState<ISelectedUserInfoFromAzure>({
      name: '',
      emailId: '',
      phone: '',
      azureId: '',
    });

  const [apiLoadersState, setApiLoadersState] = useState({
    isFetchingUsersByPrincipalIdFromAzure: false,
    isFetchingUserDetailsById: false,
    isFetchingAllRoles: false,
    isFetchingAllTitles: false,
    isFetchingStates: false,
    isFetchingAllTaskCategoriesList: false,
    isFetchingAllAssignmentLevels: false,
    isInitialFetchInProgress: false,
    isAddingNewUserInProgress: false,
  });

  const [
    getAllUsersByServicePrincipalIdFromAzureData,
    setGetAllUsersByServicePrincipalIdFromAzureData,
  ] = useState<GetADUsersByServicePrincipalIdDataRow[]>([]);

  const [availableAllTitles, setAvailableAllTitles] = useState<
    GetAllRolesDataRow[]
  >([]);
  const [availableAllRoles, setAvailableAllRoles] = useState<
    GetAllRolesDataRow[]
  >([]);

  const [selectedStatesCodeList, setSelectedStatesCodeList] = useState<
    IStateCode[]
  >([]);

  const [permittedStateOptionsList, setPermittedStateOptionsList] = useState<
    GetAllStatesDataRow[]
  >([]);

  const [allTaskCategoriesList, setAllTaskCategoriesList] = useState<
    GetAllTaskCategoriesDataRow[]
  >([]);

  const [allAssignmentsLevelsList, setAllAssignmentsLevelsList] = useState<
    GetAllAssignmentLevelsDataRow[]
  >([]);

  const [isOptionDropdownOpen, setIsOptionDropdownOpen] =
    useState<boolean>(false);

  const [isSaveAndContinueButtonDisabled, setIsSaveAndContinueButtonDisabled] =
    useState(false);

  const fetchUsersFromAzure = async (name = '') => {
    const payload: GetAllUsersByServicePrincipalIdFromAzureQueryParam = {
      name,
    };
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingUsersByPrincipalIdFromAzure = true;
      }),
    );

    try {
      const getAllUsersByServicePrincipalIdFromAzureResponse =
        await getAllUsersByServicePrincipalIdFromAzure(payload);
      setGetAllUsersByServicePrincipalIdFromAzureData([
        ...getAllUsersByServicePrincipalIdFromAzureResponse.data.rows,
      ]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingUsersByPrincipalIdFromAzure = false;
        }),
      );
    }
  };

  const fetchUserDetailsById = async (id: string) => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingUserDetailsById = true;
      }),
    );
    try {
      const getUsersByServicePrincipalIdFromAzureResponse =
        await getUserByServicePrincipalIdFromAzure(id);
      const selectedUserDetails =
        getUsersByServicePrincipalIdFromAzureResponse.data;
      setSelectedAzureUser(
        produce(draft => {
          draft.name = selectedUserDetails.name;
          draft.emailId = selectedUserDetails.email;
          draft.phone = selectedUserDetails.mobile ?? '';
          draft.azureId = selectedUserDetails.id;
        }),
      );
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingUserDetailsById = false;
        }),
      );
    }
  };

  const handleSearchUsersFromAzure = useDebouncedCallback((searchText = '') => {
    fetchUsersFromAzure(searchText);
  }, SEARCH_DEBOUNCE_DELAY_IN_MS);

  const fetchAllTitles = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllTitles = true;
      }),
    );
    try {
      const getAllTitlesResponse = await getAllTitles();
      const allTitles = getAllTitlesResponse.data.rows;
      setAvailableAllTitles([...allTitles]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllTitles = false;
        }),
      );
    }
  };

  const fetchAllRoles = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllRoles = true;
      }),
    );
    try {
      const getAllRolesResponse = await getAllRoles();
      const allTitles = getAllRolesResponse.data.rows;
      setAvailableAllRoles([...allTitles]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllRoles = false;
        }),
      );
    }
  };

  const fetchAllTaskCategories = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllTaskCategoriesList = true;
      }),
    );
    try {
      const getAllTaskCategoriesResponse = await getAllTaskCategories();
      const allTaskCategories = getAllTaskCategoriesResponse.data.rows;
      setAllTaskCategoriesList([...allTaskCategories]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllTaskCategoriesList = false;
        }),
      );
    }
  };

  const fetchAllAssignmentLevels = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllAssignmentLevels = true;
      }),
    );
    try {
      const getAllAssignmentLevelsResponse = await getAllAssignmentLevels();
      const allAssignmentLevels = getAllAssignmentLevelsResponse.data.rows;
      setAllAssignmentsLevelsList([...allAssignmentLevels]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllAssignmentLevels = false;
        }),
      );
    }
  };

  const init = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isInitialFetchInProgress = true;
      }),
    );
    try {
      await Promise.all([
        fetchUsersFromAzure(),
        fetchAllTitles(),
        fetchAllRoles(),
        fetchAllTaskCategories(),
        fetchAllAssignmentLevels(),
      ]);
    } catch (errorResponse: any) {
      const errorMessage = t('ADMIN.INIT_ERROR_MESSAGE');
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isInitialFetchInProgress = false;
        }),
      );
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = async (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    setAddNewUserFormState(
      produce(draft => {
        draft.searchUserInput = newInputValue;
      }),
    );
    handleSearchUsersFromAzure(newInputValue);
  };

  const handleSelectUserChange = async (
    event: SyntheticEvent<Element, Event>,
    selectedUserAzureId: GetADUsersByServicePrincipalIdDataRow,
  ) => {
    if (selectedUserAzureId) {
      setSelectedAzureUser({
        name: '',
        emailId: '',
        phone: '',
        azureId: '',
      });
      await fetchUserDetailsById(selectedUserAzureId.id);
    }
  };

  const validateCategories = (): boolean => {
    const adminOrManagerRoleIds = availableAllRoles
      .filter(
        role =>
          role?.label?.toLowerCase() ===
            AdminConstants.USERS.USER_SETTINGS.ROLE_ADMIN.toLowerCase() ||
          role?.label?.toLowerCase() ===
            AdminConstants.USERS.USER_SETTINGS.MANAGER.toLowerCase(),
      )
      .map(role => role.id);

    const isRoleAdminOrManager = adminOrManagerRoleIds.includes(
      addNewUserFormState.role,
    );

    if (addNewUserFormState.categories.length < 1 && !isRoleAdminOrManager) {
      enqueueSnackbar(
        t(
          'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_AT_LEAST_1_QUEUE_ASSIGNMENT',
        ),
        SnackBarConfig.getError(),
      );
      return false;
    }
    return true;
  };

  const validateSignUpUserForm = (): boolean => {
    const hasEmailBasedCategory = addNewUserFormState.categories.some(
      selectedCategory =>
        allTaskCategoriesList.some(
          category =>
            category.id === selectedCategory.taskCategoryId &&
            category.isEmailBased,
        ),
    );

    if (selectedAzureUser.azureId.length <= 2) {
      enqueueSnackbar(
        t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_A_USER'),
        SnackBarConfig.getError(),
      );
      return false;
    }

    if (addNewUserFormState.title.length <= 2) {
      enqueueSnackbar(
        t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_A_TITLE'),
        SnackBarConfig.getError(),
      );
      return false;
    }

    if (addNewUserFormState.role.length <= 2) {
      enqueueSnackbar(
        t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_A_ROLE'),
        SnackBarConfig.getError(),
      );
      return false;
    }

    if (hasEmailBasedCategory) {
      if (
        !addNewUserFormState.stateNsa ||
        (addNewUserFormState.stateNsa === StateNsaAllStateEnum.STATE &&
          !(
            addNewUserFormState.isAllState ||
            addNewUserFormState.stateIds.length > 0
          ))
      ) {
        enqueueSnackbar(
          t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_VALUE_FOR_STATE_NSA',
          ),
          SnackBarConfig.getError(),
        );
        return false;
      }
    }

    if (
      addNewUserFormState.stateNsa.toLowerCase() === StateNsaAllStateEnum.NSA ||
      addNewUserFormState.stateNsa === ''
    ) {
      return validateCategories();
    }

    if (
      addNewUserFormState.stateNsa.toLowerCase() ===
        StateNsaAllStateEnum.STATE ||
      addNewUserFormState.stateNsa === ''
    ) {
      if (addNewUserFormState.isAllState || selectedStatesCodeList.length > 0) {
        return validateCategories();
      } else {
        enqueueSnackbar(
          t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_VALID_STATE_OPTIONS',
          ),
          SnackBarConfig.getError(),
        );
        return false;
      }
    }
    return false;
  };

  const createSignUpUserPayload = (): AddUserPayloadDto => {
    const payload: AddUserPayloadDto = {
      azureUniqueId: selectedAzureUser.azureId,
      titleId: addNewUserFormState.title,
      roleIds: [addNewUserFormState.role],
      stateNsa: addNewUserFormState.isAllState
        ? StateNsaAllStateEnum.ALL_STATE
        : addNewUserFormState.stateNsa,
      taskCategories: addNewUserFormState.categories,
    };

    if (addNewUserFormState.stateIds.length > 0) {
      payload.stateIds = addNewUserFormState.stateIds;
    }

    return payload;
  };

  const signUpNewUser = async (signUpUserPayload: AddUserPayloadDto) => {
    setApiLoadersState(
      produce(draft => {
        draft.isAddingNewUserInProgress = true;
      }),
    );
    try {
      const addNewUserApiResponse = await addUser(signUpUserPayload);
      enqueueSnackbar(
        addNewUserApiResponse.data.message,
        SnackBarConfig.getSuccess(),
      );
      navigate(
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes.ActiveUsers
          .FullPath,
      );
    } catch (errorResponse: any) {
      enqueueSnackbar(
        errorResponse?.error?.message ?? '',
        SnackBarConfig.getError(),
      );
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isAddingNewUserInProgress = false;
        }),
      );
    }
  };

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit = async () => {
    setIsSaveAndContinueButtonDisabled(true);
    try {
      const validationResult = validateSignUpUserForm();

      if (validationResult) {
        const signUpPayload = createSignUpUserPayload();
        await signUpNewUser(signUpPayload);

        setIsSaveAndContinueButtonDisabled(false);
        reset();
        onSubmitDialogClose();
        navigate(PrivateNavigationRoutes.Admins.ChildRoutes.Users.FullPath, {
          state: {
            addedNewUserSuccessfully: true,
          },
        });
      }
    } catch (errorResponse: any) {
      enqueueSnackbar(
        errorResponse?.error?.message ?? '',
        SnackBarConfig.getError(),
      );
    } finally {
      setIsSaveAndContinueButtonDisabled(false);
    }
  };

  const removeSelectedUser = async () => {
    setAddNewUserFormState(initialAddNewUserFormState);
    setSelectedStatesCodeList([]);
    setSelectedAzureUser(
      produce(draft => {
        draft.name = '';
        draft.emailId = '';
        draft.phone = '';
        draft.azureId = '';
      }),
    );

    await init();
  };

  const renderSelectedUserInfoFromAzure = () => (
    <SelectedUserInfoContainer>
      <SelectedUserInfo>
        <SelectedUserName>{selectedAzureUser.name}</SelectedUserName>
        <SelectedUserEmailId>{selectedAzureUser.emailId}</SelectedUserEmailId>
        <SelectedUserPhoneNumber>
          {selectedAzureUser.phone ?? ''}
        </SelectedUserPhoneNumber>
      </SelectedUserInfo>
      <RemoveSelectedUser onClick={removeSelectedUser}>
        {t('ADMIN.USERS_TAB.ADD_USER_FORM.REMOVE')}
      </RemoveSelectedUser>
    </SelectedUserInfoContainer>
  );

  const renderSearchAndSelectUserDropdown = () => {
    return (
      <SearchAndSelectUserDropDownContainer>
        <Controller
          name="selectUsers"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <LabelStyled>
                {t('ADMIN.USERS_TAB.ADD_USER_FORM.SEARCH_AND_SELECT_USER')}
              </LabelStyled>
              <SelectSearchInput
                loading={
                  (apiLoadersState.isFetchingUsersByPrincipalIdFromAzure ||
                    apiLoadersState.isFetchingUserDetailsById) &&
                  !apiLoadersState.isInitialFetchInProgress
                }
                value={null}
                onChange={(e, newValue) =>
                  handleSelectUserChange(
                    e,
                    newValue as GetADUsersByServicePrincipalIdDataRow,
                  )
                }
                inputValue={addNewUserFormState.searchUserInput}
                onInputChange={handleInputChange}
                shouldRenderOptionsManually={true}
                options={getAllUsersByServicePrincipalIdFromAzureData}
                getOptionLabel={(
                  option: string | GetADUsersByServicePrincipalIdDataRow,
                ) => (typeof option === 'string' ? option : option.name)}
                includeInputInList={false}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'ADMIN.USERS_TAB.ADD_USER_FORM.SEARCH_AND_SELECT',
                    )}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <img src={SearchIcon} alt="search" />,
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </>
          )}
        />
      </SearchAndSelectUserDropDownContainer>
    );
  };

  const handleTitleSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    setAddNewUserFormState(
      produce(draft => {
        draft.title = event.target.value as string;
      }),
    );
  };

  const handleRoleSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) =>
    setAddNewUserFormState(
      produce(draft => {
        draft.role = event.target.value as string;
      }),
    );

  const renderTitleAndRoleSelectionDropdown = () => {
    return (
      <TitleAndRoleSelectionDropdownContainer>
        <TitleSelectionDropdownContainer>
          <TitleAndRoleLabel>
            {t('ADMIN.USERS_TAB.ADD_USER_FORM.TITLE_REQUIRED')}
          </TitleAndRoleLabel>
          <TitleSelectionDropdown>
            <SelectTextInput
              options={mapDropdownOptionToDropdownOptionsType(
                availableAllTitles,
              )}
              disabled={selectedAzureUser.azureId.length < 2}
              onChange={handleTitleSelectChange}
              variant={'filled'}
              placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
              width={'12.1875rem'}
              label={t('ADMIN.USERS_TAB.ADD_USER_FORM.TITLE_REQUIRED')}
              selectValue={addNewUserFormState.title}
            />
          </TitleSelectionDropdown>
        </TitleSelectionDropdownContainer>
        <RoleSelectionDropdownContainer>
          <TitleAndRoleLabel>
            {t('ADMIN.USERS_TAB.ADD_USER_FORM.ROLE_REQUIRED')}
          </TitleAndRoleLabel>
          <RoleSelectionDropdown>
            <SelectTextInput
              options={mapDropdownOptionToDropdownOptionsType(
                availableAllRoles,
              )}
              disabled={selectedAzureUser.azureId.length < 2}
              showPlaceHolder={true}
              onChange={handleRoleSelectChange}
              variant={'filled'}
              placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
              width={'12.1875rem'}
              label={t('ADMIN.USERS_TAB.ADD_USER_FORM.ROLE_REQUIRED')}
              selectValue={addNewUserFormState.role}
            />
          </RoleSelectionDropdown>
        </RoleSelectionDropdownContainer>
      </TitleAndRoleSelectionDropdownContainer>
    );
  };

  const fetchStatesList = async (newSearchStateInputValue = '') => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingStates = true;
      }),
    );
    try {
      const payload: GetAllStatesQueryParam = {
        label: newSearchStateInputValue,
      };
      const getAllStatesListResponse = await getAllStates(payload);
      const allStatesList = getAllStatesListResponse.data.rows;
      const allStatesOption: GetAllStatesDataRow = {
        id: StateNsaAllStateEnum.ALL_STATE,
        label: AdminConstants.USERS.ALL_CAPITALIZED,
        nameId: AdminConstants.USERS.ALL,
        code: AdminConstants.USERS.ALL,
      };
      setPermittedStateOptionsList([allStatesOption, ...allStatesList]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingStates = false;
        }),
      );
    }
  };

  const handleSearchStates = useDebouncedCallback((searchText = '') => {
    fetchStatesList(searchText);
  }, SEARCH_DEBOUNCE_DELAY_IN_MS);

  const handleStateNsaOptionChange = async (
    e: SelectChangeEvent<string | string[]>,
  ) => {
    const selectedOptionValue = e.target.value as string;
    setAddNewUserFormState(
      produce(draft => {
        draft.stateNsa = selectedOptionValue;
      }),
    );
    if (selectedOptionValue.toLowerCase() === StateNsaAllStateEnum.NSA) {
      setSelectedStatesCodeList([]);
      setAddNewUserFormState(
        produce(draft => {
          draft.stateIds = [];
        }),
      );
    }
    if (selectedOptionValue.toLowerCase() === StateNsaAllStateEnum.STATE) {
      await fetchStatesList();
    }
  };

  const handleSearchStateInputChange = (newStateInputValue: string) => {
    setAddNewUserFormState(
      produce(draft => {
        draft.searchStateInput = newStateInputValue;
      }),
    );
    handleSearchStates(newStateInputValue);
  };

  const handleSelectStateChange = (
    event: SyntheticEvent<Element, Event>,
    selectedStateOption: GetAllStatesDataRow,
  ) => {
    // implemented fn which would remove all from list if available and selectedStateId is all along with the state codes
    if (selectedStateOption.nameId === AdminConstants.USERS.ALL.toLowerCase()) {
      setAddNewUserFormState(
        produce(draft => {
          draft.stateIds = [];
          draft.isAllState = true;
          draft.searchStateInput = '';
        }),
      );
      const allStateCode: IStateCode = {
        id: AdminConstants.USERS.ALL.toLowerCase(),
        stateCode: AdminConstants.USERS.ALL.toUpperCase(),
      };
      setSelectedStatesCodeList([allStateCode]);
      handleSearchStateInputChange('');
    } else {
      const isStateIdAlreadyPresent = addNewUserFormState.stateIds.filter(
        stateId => stateId === selectedStateOption.id,
      );
      if (isStateIdAlreadyPresent.length === 0) {
        const newSelectedStatesCodeList = [
          ...selectedStatesCodeList,
          { id: selectedStateOption.id, stateCode: selectedStateOption.code },
        ].filter(state => state.id !== AdminConstants.USERS.ALL.toLowerCase());

        setSelectedStatesCodeList(newSelectedStatesCodeList);

        const newStateIds = [
          ...addNewUserFormState.stateIds,
          selectedStateOption.id,
        ];

        setAddNewUserFormState(
          produce(draft => {
            draft.stateNsa = StateNsaAllStateEnum.STATE;
            draft.stateIds = newStateIds;
            draft.isAllState = false;
            draft.searchStateInput = '';
          }),
        );
        handleSearchStateInputChange('');
      } else {
        enqueueSnackbar(
          `${t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.STATE')} ${
            selectedStateOption.label
          } ${t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.IS_ALREADY_SELECTED',
          )}`,
          SnackBarConfig.getInfo(),
        );
      }
    }
  };

  const renderSelectStateList = () => {
    return (
      <SelectStateListContainer>
        <Controller
          name="selectPermittedStates"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <LabelStyled>
                {t(
                  'ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_PERMITTED_STATES_REQUIRED',
                )}
              </LabelStyled>
              <SelectSearchInput
                sx={{
                  // Remove padding for the input root when Autocomplete hasPopupIcon
                  '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
                    paddingRight: 0,
                  },
                  // Remove padding for the outlined input root when Autocomplete hasPopupIcon or hasClearIcon
                  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
                    {
                      paddingRight: 1.5,
                    },
                  '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                    padding: 0.7,
                  },
                }}
                loading={
                  apiLoadersState.isFetchingStates &&
                  !apiLoadersState.isInitialFetchInProgress
                }
                setIsOptionDropdownOpen={setIsOptionDropdownOpen}
                clearOnBlur={false}
                value={null}
                onChange={(e, newValue) =>
                  handleSelectStateChange(e, newValue as GetAllStatesDataRow)
                }
                inputValue={addNewUserFormState.searchStateInput}
                onInputChange={(event, value) => {
                  handleSearchStateInputChange(value);
                }}
                options={permittedStateOptionsList}
                getOptionLabel={(option: string | GetAllStatesDataRow) =>
                  typeof option === 'string' ? option : option.label
                }
                includeInputInList
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'ADMIN.USERS_TAB.ADD_USER_FORM.SEARCH_STATE_AND_SELECT',
                    )}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null,
                      endAdornment: (
                        <CustomImg
                          src={ArrowSolidIcon}
                          alt="toggle state dropdown"
                          style={{
                            transform: isOptionDropdownOpen
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </>
          )}
        />
      </SelectStateListContainer>
    );
  };

  const handleDeleteStateCodeChip = (stateCode: IStateCode) => () => {
    const newSelectedStateCodeList = selectedStatesCodeList.filter(
      selectedState => selectedState.id !== stateCode.id,
    );
    setSelectedStatesCodeList([...newSelectedStateCodeList]);
    const newSelectedStateIds = addNewUserFormState.stateIds.filter(
      stateId => stateId !== stateCode.id,
    );
    setAddNewUserFormState(
      produce(draft => {
        draft.stateIds = newSelectedStateIds;
      }),
    );
  };

  const renderStateCodeChips = () => {
    return (
      <StateCodeChipsContainer>
        {selectedStatesCodeList.map(selectedState => {
          return (
            <StyledCustomChip
              key={selectedState.id}
              label={selectedState.stateCode}
              onDelete={handleDeleteStateCodeChip(selectedState)}
              variant={undefined}
              deleteIcon={<img src={CrossIcon} alt="close" />}
            />
          );
        })}
      </StateCodeChipsContainer>
    );
  };

  const renderStateNsaDropdowns = () => {
    return (
      <StateNsaContainer>
        <StateNsaTitle>
          {t('ADMIN.USERS_TAB.ADD_USER_FORM.STATE_NSA')}
        </StateNsaTitle>
        <SelectStateNsaDropdownContainer>
          <SelectStateNsaDropdownTitle>
            {t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_REQUIRED')}
          </SelectStateNsaDropdownTitle>
          <SelectStateNsaDropdownWrapper>
            <SelectTextInput
              options={selectStateNsaDropdownOption}
              disabled={selectedAzureUser.azureId.length < 2}
              showPlaceHolder={true}
              onChange={handleStateNsaOptionChange}
              variant={'filled'}
              placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
              label={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_REQUIRED')}
              selectValue={addNewUserFormState.stateNsa}
              enableSelect={false}
            />
          </SelectStateNsaDropdownWrapper>
          {addNewUserFormState.stateNsa === StateNsaAllStateEnum.STATE
            ? renderSelectStateList()
            : null}
        </SelectStateNsaDropdownContainer>
      </StateNsaContainer>
    );
  };

  const handleOnSelectionChangeOfCategoryAssignmentLevelsList = (
    value: string,
    taskCategory: GetAllTaskCategoriesDataRow,
  ) => {
    const newCategories = addNewUserFormState.categories.map(category => {
      if (category.taskCategoryId === taskCategory.id) {
        const newCategory = {
          ...category,
          assignmentLevelId: value,
        };
        return newCategory;
      }
      return category;
    });

    setAddNewUserFormState(
      produce(draft => {
        draft.categories = newCategories;
      }),
    );
  };

  const handleOnChangeQueueAssignmentOptionCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    taskCategory: GetAllTaskCategoriesDataRow,
  ) => {
    if (event.target.checked) {
      setAddNewUserFormState(
        produce(draft => {
          draft.categories = [
            ...draft.categories,
            {
              taskCategoryId: taskCategory.id,
              assignmentLevelId: allAssignmentsLevelsList.filter(
                assignmentLevel =>
                  assignmentLevel.nameId === AdminConstants.USERS.PRIMARY,
              )[0].id,
            },
          ];
        }),
      );
    } else {
      setAddNewUserFormState(
        produce(draft => {
          draft.categories = draft.categories.filter(
            category => category.taskCategoryId !== taskCategory.id,
          );
        }),
      );
    }
  };

  const renderQueueAssignmentOption = (
    taskCategory: GetAllTaskCategoriesDataRow,
  ) => {
    const isSelected = addNewUserFormState.categories.some(
      category => category.taskCategoryId === taskCategory.id,
    );

    return (
      <QueueAssignmentOptionContainer key={taskCategory.id}>
        <div>
          <FormControlLabel
            control={
              <CheckboxStyled
                disabled={selectedAzureUser.azureId.length < 2}
                onChange={event =>
                  handleOnChangeQueueAssignmentOptionCheckbox(
                    event,
                    taskCategory,
                  )
                }
                color="secondary"
                checked={isSelected} // Check if the category is selected
              />
            }
            label={taskCategory.label}
            value={taskCategory.id}
          />
        </div>
        <TabFilterContainer>
          {isSelected && ( // Render TabFilter only if the category is selected
            <TabFilter
              filterOptions={mapDropdownOptionToDropdownOptionsType(
                allAssignmentsLevelsList,
              )}
              onSelectionChange={(value: string) =>
                handleOnSelectionChangeOfCategoryAssignmentLevelsList(
                  value,
                  taskCategory,
                )
              }
            />
          )}
        </TabFilterContainer>
      </QueueAssignmentOptionContainer>
    );
  };

  const renderQueueAssignments = () => {
    return (
      <QueueAssignmentsContainer>
        <QueueAssignmentsTitle>
          {t('ADMIN.USERS_TAB.ADD_USER_FORM.QUEUE_ASSIGNMENTS')}
        </QueueAssignmentsTitle>
        <QueueAssignmentsOptionsContainer>
          <FormGroup>
            {/* map over list of queue assignments and use the renderQueueAssignmentOption */}
            {allTaskCategoriesList.map(taskCategory =>
              renderQueueAssignmentOption(taskCategory),
            )}
          </FormGroup>
        </QueueAssignmentsOptionsContainer>
      </QueueAssignmentsContainer>
    );
  };

  const renderDialogContent = () => {
    return (
      <DialogContentContainer>
        {apiLoadersState.isInitialFetchInProgress ? (
          <MainLoaderContainer>
            <Loader size={60} />
          </MainLoaderContainer>
        ) : null}
        <>
          {renderSearchAndSelectUserDropdown()}
          {selectedAzureUser.azureId ? renderSelectedUserInfoFromAzure() : null}
          <FormAfterSelectingUserFromAzure
            disabled={selectedAzureUser.azureId.length < 2}
          >
            {renderTitleAndRoleSelectionDropdown()}
            <DividerStyled />
            {renderStateNsaDropdowns()}
            {selectedStatesCodeList.length ? renderStateCodeChips() : null}
            <DividerStyled />
            {renderQueueAssignments()}
            <DividerStyled ending={true} />
          </FormAfterSelectingUserFromAzure>
        </>
      </DialogContentContainer>
    );
  };

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onClose}
      titleText={t('ADMIN.USERS_TAB.ADD_USER_FORM.TITLE_ADD_NEW_USER')}
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText={t('ADMIN.USERS_TAB.ADD_USER_FORM.SUB_TITLE_FILL_OUT_FORM')}
      dialogContent={renderDialogContent()}
      submitButtonText={t('ADMIN.USERS_TAB.ADD_USER_FORM.SAVE_AND_ADD')}
      submitButtonLoadingText={t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.SAVE_AND_ADD_LOADING',
      )}
      cancelButtonText={t('ADMIN.USERS_TAB.ADD_USER_FORM.CANCEL')}
      onSubmit={handleSubmit(onSubmit)}
      isSaveInProgress={apiLoadersState.isAddingNewUserInProgress}
      disableSubmitButton={
        isSaveAndContinueButtonDisabled ||
        apiLoadersState.isAddingNewUserInProgress ||
        selectedAzureUser.azureId.length < 2
      }
    />
  );
};

export default UsersPuAddNewUserDialog;
