import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { APP } from 'src/styles/variables';

type EmailResponseDetailsContainerGridType = {
  isLastEmailThreadIndex?: boolean;
};

export const EmailResponseDetailsContainerGrid = styled(
  Grid,
)<EmailResponseDetailsContainerGridType>`
  width: auto;
  height: fit-content;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  margin: 0.625rem 0.625rem 0 0.625rem;
  margin-bottom: ${({ isLastEmailThreadIndex = false }) =>
    isLastEmailThreadIndex ? '0.625rem' : '0'};
`;

export const EmailDivStyled = styled('div')`
  margin-bottom: 0.188rem;
`;

export const ResponseKeywordDivStyled = styled('div')`
  margin-bottom: 11px;
`;

export const DateAndFromEmailDiv = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ResponseKeyword = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const KeywordStyledSpan = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.SUBTITLE1};
`;

export const DateStyledSpan = styled.p`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_13PX};
  color: ${APP.PALETTE.GREY.SHADE_600};
  font-weight: ${APP.FONT.FONT_WEIGHT.SUBTITLE1};
`;

export const ValueStyledSpan = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  margin-left: 0.313rem;
`;

type RichTextEmailResponseViewerType = {
  issubjectviewer: 'yes' | 'no';
};

export const RichTextEmailResponseViewer = styled(
  ReactQuill,
)<RichTextEmailResponseViewerType>`
  border: none;
  width: 100%;
  height: fit-content;
  margin-top: 0.375rem;

  .ql-container.ql-snow {
    border: none;
    font-size: ${({ issubjectviewer }) =>
      issubjectviewer === 'yes' ? '1.312rem' : ''};
  }

  .ql-editor {
    padding: 0 !important;
  }
`;

export const EmailResponseAttachmentWrapperGrid = styled(Grid)`
  width: 100%;
  min-height: 3.3rem;
  height: 3.3rem;
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0.312rem 0 0.312rem 0;
  overflow: auto;
  gap: 0.5rem;
  overflow-y: hidden;
`;

export const EmailResponseAttachmentContentGrid = styled('div')`
  width: fit-content;
  min-height: 2.18rem;
  height: 2.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${APP.PALETTE.SECONDARY.MAIN};
  padding: 0.5rem 0.6rem;
  cursor: pointer;
`;

export const EmailAttachmentIconImage = styled('img')`
  padding: 0.125rem 0.25rem 0.125rem 0.125rem;
  height: 1.375rem;
  width: 1.375rem;
`;

export const EmailAttachmentNameTypography = styled(Typography)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: 0.875rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.SUBTITLE1};
  white-space: nowrap;
`;
