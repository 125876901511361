import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { LabelStyled } from './SelectTextInputStyle';
import { APP } from 'src/styles/variables';
import { CustomOutlinedInput } from '@components/custom-outlined-input/CustomOutlinedInput';

interface Option {
  value: string;
  label: string;
}

interface SelectTextInputProps extends Omit<SelectProps, 'value' | 'onChange'> {
  options: Option[];
  placeholder?: string;
  showPlaceHolder?: boolean;
  defaultValue?: string[];
  labelText?: string;
  onChange: (e: SelectChangeEvent<string[] | string>) => void;
  handleChange?: (value: SelectChangeEvent<string[] | string>) => void;
  width?: string;
  height?: string;
  selectValue?: string;
  enableSelect?: boolean;
}

const SelectTextInput: React.FC<SelectTextInputProps> = ({
  options,
  placeholder = 'Placeholder',
  showPlaceHolder = false,
  labelText = '',
  onChange,
  handleChange,
  width,
  height,
  selectValue = '',
  enableSelect = true,
  ...rest
}) => {
  return (
    <div>
      <FormControl>
        <LabelStyled>{labelText}</LabelStyled>
        <Select
          value={selectValue}
          displayEmpty
          onChange={(e: SelectChangeEvent<string[] | string>) => {
            onChange(e);
            if (handleChange) {
              handleChange(e);
            }
          }}
          input={
            <CustomOutlinedInput
              style={{
                width: width ?? '25rem',
                height: height ?? '3rem',
                borderRadius: '4px',
                border: `1px solid ${APP.PALETTE.GREY.SEMI_GRAY}`,
              }}
            />
          }
          {...rest}
        >
          <MenuItem disabled={enableSelect} value="">
            <em>{placeholder}</em>
          </MenuItem>

          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectTextInput;
