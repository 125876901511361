import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';
import theme from 'src/styles/theme';
import { Grid, Typography } from '@mui/material';
import TextButton from '@components/buttons/text-button/TextButton';

export const TabItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props =>
    props.isActive
      ? props.theme.palette.common.black
      : APP.PALETTE.GREY.TERTIARY_COLOR};
  border-bottom: ${props =>
    props.isActive
      ? `6px solid ${props.theme.palette.secondary.main}`
      : 'none'};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: 1rem;
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};

  &:focus-visible {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:active {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:hover {
    outline: none;
    color: ${theme.palette.common.black};
    background-color: ${props =>
      props.isActive ? 'none' : APP.PALETTE.GREY.LIGHT_GREY};
  }

  position: relative;
`;

export const TabItemText = styled.div<{ isActive: boolean }>`
  position: relative;
  top: ${props => (props.isActive ? `3px` : '0')};
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  margin-left: 1rem;
  height: 100%;
`;

export const EmailReplyButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const ReplyTextButton = styled(TextButton)`
  width: 80px;
  border-radius: 0;
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  color: ${APP.PALETTE.COMMON.WHITE};
  padding: 2px;
  margin-left: 0.625rem;
  &:hover {
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
  }
`;

export const EmailAndThreadMainContainer = styled.div``;

export const EmailReplyTopBarContainer = styled.div`
  margin: 0 8px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const EmailReplyLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${APP.PALETTE.BACKGROUND.WHITE_WITH_80_PERCENT_OPACITY};
  z-index: 1000;
`;

export const EmailReplyTopBar = styled(Grid)`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.palette.background.paper};
  border-bottom: 1px solid ${APP.PALETTE.DASHBOARD.BORDER_COLOR.PRIMARY};
`;

export type EmailAndThreadBodyContainerType = {
  isEmailViewerOnLeft?: boolean;
};

export const EmailAndThreadBodyContainer = styled.div<EmailAndThreadBodyContainerType>``;

export const EmailReplyContainer = styled.div``;

export const EmailReplyFormContainer = styled.div`
  position: sticky;
  top: 55px;
  z-index: 1;
`;

export const EmailThreadDetailsLoadingGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
`;

export const EmailThreadDetailsNotFoundViewerWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;

export const EmailDetailsNotFoundTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A400};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const Divider = styled.div`
  margin: 0 10px;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;
